import axios from 'axios';
import {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {loginUser} from '../actions/authActions'

const errStyle = {
    color: 'red',
    textAlign: 'center',
    fontSize: '.7rem'
}

function Login(props) {
    const [user, setUser] = useState({
        userName:'',
        password:'',
    })
    const [emailError, setEmailError] = useState('')
    const [clickForgot, setClickForgot] = useState(true)
    const errors = useSelector(state => state.errors)
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch();
    
    useEffect(() => { 
       if(auth.isAuthenticated) {
           props.history.push('/user')
       }
    }, []);

    useEffect(() => {
        if(auth.isAuthenticated) {
            props.history.push('/user')
        }
    })

    const handleChage = (e) => {
        const { id, value } = e.target;
        setUser(user => ({ ...user, [id]: value }));
        setEmailError('')
    }

    const submitForm = () => {
        dispatch(loginUser({email:user.userName, password:user.password, url:props.location.state ? props.location.state.url : ''}))
    }

    const forgotPassword = (e) => {
        if(user.userName.length > 0) {
            if(clickForgot) {
                axios.post('v1/users/forgotpassword',{ 
                    email:user.userName
                })
                .then(res => 
                    {
                        setClickForgot(false)
                        setEmailError(res.data)
                    })
                .catch(err => {
                    // console.log(err)
                })
            } else {
                setEmailError('We already sent a password reset link, please check your mail')
            }
            // setEmailError('')
        } else {
            setEmailError('Please enter email id')
        }
    }

    return (
        <div>
            {/* <input type="text" onChange={(e) => handleChage(e)} id="userName" placeholder="User Email"></input>
            <input type="password" onChange={(e) => handleChage(e)} id="password" placeholder="User Passwrod"></input>

            <button onClick={(e) => submitForm(e)}>Submit</button> */}
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <h1 className="text-center">Log In</h1>
                        </div>
                    </div>
                </div>
            </header>
            <div className="ex-form-1 pt-5 pb-5">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 offset-xl-3">
                    <div className="text-box mt-5 mb-5">
                        <p style={{textAlign: 'center', color:'red'}}>{props.location.state ? props.location.state.text : ''}</p>
                        <p style={{textAlign: 'center'}}>You don't have a account? then please <a className="blue" href="/register">Register</a></p>
                            <div className="form-group">
                                <input type="email" onChange={(e) => handleChage(e)} className="form-control-input notEmpty" id="userName" />
                                <label className="label-control" htmlFor="email">Email</label>
                                <p style={errStyle}>{errors.email}</p>
                            </div>
                            <div className="form-group">
                                <input type="password" onChange={(e) => handleChage(e)} className="form-control-input notEmpty" id="password" />
                                <label className="label-control" htmlFor="password">Password</label>
                                <p style={errStyle}>{errors.password}</p>
                            </div>
                            <div style={{textAlign: 'right'}}>
                                <p style={{color: 'blue', cursor: 'pointer'}} onClick={(e) => forgotPassword(e)}>Forgot Password ?</p>
                            </div>
                            <div>
                                <p style={errStyle}>{emailError}</p>
                            </div>
                            <div className="form-group checkbox" style={{ textAlign: 'center' }}>
                                {/* <input type="checkbox" id="terms" value="Agreed-to-Terms" /> */}
                                I agree with the site's stated <a href="/privacy">Privacy Policy</a> and <a href="/termsandconditions">Terms & Conditions</a>
                            </div>
                            <p style={errStyle}>{errors.notverified ? errors.notverified : errors.emailnotfound ? errors.emailnotfound : errors.passwordincorrect ? errors.passwordincorrect : ''}</p>
                            <div className="form-group">
                                <button type="submit" onClick={(e) => submitForm(e)} className="form-control-submit-button">Log In</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
    );
}

export default Login;