import ButtonBackCat from './Constants/ButtonCat';

function About() {
    return (
        <>
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <h1>About Smart Uniforms</h1>
                        </div>
                        <ButtonBackCat />
                    </div>
                </div>
            </header>
            <div className="ex-basic-1 pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" style={{ textAlign: 'center' }}>
                            <img className="img-fluid mt-5 mb-3" src="images/logo.png" style={{ width: '500px'}} alt="smart uniforms" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="ex-basic-1 pt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <p style={{ textAlign: 'justify' }}><b>‘SMART UNIFORMS’</b> are happy to introduce our self as leading manufactures in high quality uniforms with a vision of offering only best products tailor made to suit the market needs through innovations. Our brand is well known for usage of   fabrics and accessories from reputed manufacturers in India for our popular products like uniforms & apparels.</p>
                            <p className="mb-3" style={{ textAlign: 'justify' }}>Besides maintaining its superior quality products, Smart Uniforms is also known for its excellent customer - service with well trained, dedicated, courteous and efficient staff, with more than <b>18 years</b> of experience. Our brand has established itself as a premier manufacturer of school uniforms and it has umbrella of client list includes some of the biggest and reputed schools of Bangalore and other educational hubs in Karnataka.</p>

                            {/* <h2 className="mb-3">Advantages of working with this template</h2> */}
                            <p style={{ textAlign: 'justify' }}>No matter what the job is, you can be sure Smart Uniforms has the right work-wear tailor made for you. Our products give smart look and value addition to your professional career. Made from high quality resources, our uniforms fit well and are comfortable.</p>
                            <p className="mb-3" style={{ textAlign: 'justify' }}>Our work experience with leading schools has helped us to develop deep insights into the functional requirements of children’s uniforms.</p>
                            <p style={{ textAlign: 'justify' }}>
                            We provide consumer need based uniforms in a variety of pattern and fabrics, along with accessories such as <b>“Blazers, Sweaters, Belts, Caps, Ties, Socks, Name Plates, Bloomers, Yoga Pant”</b> with competitive prices. Our uniforms adhere to child-safety norms and guidelines. High quality standards and consistency are maintained as we custom manufacture the fabrics to meet technical specifications as per the regulatory norms. The base cloth and accessories used for finished product development have been tested by Textile committee <b>(Ministry of Textile, Government of India),</b> then manufactured the garments in our own factory with advanced facility. Our brand values include, unique Individual measurement system, personalized delivery and our extremely efficient after-sales service is making us the most sought after compare to other uniforms provider in the market. We offer a friendly, organized and efficient in-store shopping experience.
                            </p>
                            <p className="mb-3" style={{ textAlign: 'justify' }}>It has always been our pledge to dedicate our efforts and through our value based feedback network to provide fashion forward designs, enhanced fabrics and “in stock” shopping. We are committed to providing best products meet the aspirations at individual level, our customer community which results in the supply of superior and most exciting garments available in our industry.</p>
                            <p className="mb-3" style={{ textAlign: 'justify' }}>With unique quality control parameters like sizing, patterns, stitch, accessories etc., we take the system very seriously to implement good manufacturing practices and make sure we adhere to it for consistence results. Our advanced manufacturing facility includes State of the Art machineries for computerized embroidery of your logos, Japanese sewing technology for stitching; latest apparel finishing technologies for producing novel designsready to create your high quality, value-for-money uniforms. Looking forward to collaborate with your esteemed organisation.</p>
                            {/* <p className="mb-3">Our clients include various prestigious educational institutions. Such as:</p>
                            <ul className="list-unstyled li-space-lg mb-5">
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>The International School Bangalore (TISB),</strong> Sarjapur Road.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>National Public School,</strong> Kengeri.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>National Public School,</strong> Chikkabanavara.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>National Public School,</strong> Gottigere.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>National Public School,</strong> Vijayanagar.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>National Public School,</strong> Jayanagar.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>National Public School,</strong> Doddakkallasandra.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>Janan Sweekar Public School,</strong> Thalaghattapura.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>Deva Matha Central School,</strong> Horamavu.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>Deva Matha Central School,</strong> Vidhyaranyapura.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>The Green Wood High School,</strong> Sarjapur Road.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>Saraswathi Vidyalaya,</strong> Hennur.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>S.L.N Convent,</strong> Mandya.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>R.K. Educational Institute,</strong> Mandya.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>Aditya Public School,</strong> Doddaballapura.</div>
                                </li>
                                <li className="media">
                                    <i className="fas fa-square"></i>
                                    <div className="media-body"><strong>Janani Vidya Mandira (JVM),</strong> Ullal.</div>
                                </li>
                            </ul>
                            <p style={{marginTop: '-25px', marginLeft: '250px'}}>& many more...</p>
                            <p className="mb-5">With quality controls on sizing, patterns, stitch, accessories etc., we take the system very seriously and make sure we adhere to it. State of the Art machineries for computerized embroidery of your logos, Japanese sewing technology for stitching, latest apparel finishing technologies for finishes are used to create your high quality, value-for-money uniforms.</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About
