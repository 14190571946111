import axios from 'axios'
import {useEffect, useState} from 'react'
import moment from 'moment'

function Invoice(props) {
    const [orderData, setOrderData] = useState([])

    useEffect(() =>{
        document.getElementById('navbarhide').style.display = 'none'
        document.getElementById('footerhide').style.display = 'none'
        getDataFromProducts(props.match.params.id)
    },[])

    const getDataFromProducts = (id) => {
        var url = "../v1/products/getsingleproduct/"
        axios.get(url + id)
            .then(res => {
                // console.log(res.data)
            setOrderData(res.data)})
            .catch(err => 
                {// console.log(err)
                }
                )
    }

    const convertNumberToWords = (amount) => {
        var words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';
        amount = amount.toString();
        var atemp = amount.split(".");
        var number = atemp[0].split(",").join("");
        var n_length = number.length;
        var words_string = "";
        if (n_length <= 9) {
            var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
            var received_n_array = new Array();
            for (var i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
                n_array[i] = received_n_array[j];
            }
            for (var i = 0, j = 1; i < 9; i++, j++) {
                if (i === 0 || i === 2 || i === 4 || i === 7) {
                    if (n_array[i] === 1) {
                        n_array[j] = 10 + parseInt(n_array[j]);
                        n_array[i] = 0;
                    }
                }
            }
            var value = "";
            for (var i = 0; i < 9; i++) {
                if (i === 0 || i === 2 || i === 4 || i === 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value !== 0) {
                    words_string += words[value] + " ";
                }
                if ((i === 1 && value !== 0) || (i === 0 && value !== 0 && n_array[i + 1] === 0)) {
                    words_string += "Crores ";
                }
                if ((i === 3 && value !== 0) || (i === 2 && value !== 0 && n_array[i + 1] === 0)) {
                    words_string += "Lakhs ";
                }
                if ((i === 5 && value !== 0) || (i === 4 && value !== 0 && n_array[i + 1] === 0)) {
                    words_string += "Thousand ";
                }
                if (i === 6 && value !== 0 && (n_array[i + 1] !== 0 && n_array[i + 2] !== 0)) {
                    words_string += "Hundred and ";
                } else if (i === 6 && value !== 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");
        }
        return words_string + 'Rupees Only' ;
    }
    // const getCountAmount = () => {
    //     data.reduce((a, b) =>  a.baseAmount * a.quantity + b.baseAmount * b.quantity, 0)
    // } 

    const getlength = (number) => {
        if(number === null) {
            return '' 
        } else {
            return number.toString().length;
        }
    }

    return (
        <>
        {/* Sender Data */}
        <div style={{textAlign: 'center'}}>
        <h3>INVOICE BILL NUMBER : SUWEB-IN-{getlength(orderData.INVOICEID ? orderData.INVOICEID : null) === 1 ? '000' : getlength(orderData.INVOICEID ? orderData.INVOICEID : null) === 2 ? '00' : getlength(orderData.INVOICEID ? orderData.INVOICEID : null) === 3 ? '0' : orderData.INVOICEID ? orderData.INVOICEID : null}{orderData.INVOICEID ? orderData.INVOICEID : null}</h3> <br />
        <h3>ORDER DETAILS</h3>
        </div>
        {orderData.productsList ? <>
            <table style={{borderCollapse:"collapse",width:"100%",borderTop:"1px solid #dddddd",borderLeft:"1px solid #dddddd",marginBottom:"20px"}}>
                <thead>
                    <tr>
                        <td style={{fontSize:"12px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"left",padding:"7px",color:"#222222"}}><b>Order Number : SUWEB-OR-{getlength(orderData.orderNo ? orderData.orderNo : null) === 1 ? '000' : getlength(orderData.orderNo ? orderData.orderNo : null) === 2 ? '00' : getlength(orderData.orderNo ? orderData.orderNo : null) === 3 ? '0' : orderData.orderNo ? orderData.orderNo : null}{orderData.orderNo ? orderData.orderNo : null}</b></td>
                        <td style={{fontSize:"12px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"left",padding:"7px",color:"#222222"}}><b style={{fontSize:'17px'}}>SHIP TO ADDRESS</b></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{fontSize:"16px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"left",padding:"7px"}}>
                            <img src='../images/invoice.png' style={{width:'300px'}}/> <br/>
                            <b>SMART UNIFORMS</b><br/>
                            <b># 44, 8th Cross, HBCS Layout</b><br/>
                            <b>Chandra Layout, Police Station Road</b><br/>
                            <b>Near Gurushree Hospital</b><br/>
                            <b>Bangalore – 560040</b><br/> <br />
                            <br/>
                            <b>GST No : </b> 29ALTPG3348M1ZD <br/>
                            <b>Mobile : </b> (+91) 95 3883 1666 <br />
                            <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>  (+91) 97 3131 2666 <br />
                            <b>Email : </b> smartuniforms14@gmail.com <br />
                            <b>Website : </b> <a href="https://smartuniforms.in">https://smartuniforms.in</a> <br/>
                            

                            
                            {/* <b>Shipping Method:</b> Reduced Shipping (Weight: 0.05kg) */}
                        </td>
                        <td style={{fontSize:"16px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"left",padding:"7px"}}>
                            <b>{orderData.userAddress ? orderData.userAddress[0].fName : ''}{' '}{orderData.userAddress ? orderData.userAddress[0].lName : ''}<br />{orderData.userAddress ? orderData.userAddress[0].addressLine1 : ''}<br/>{orderData.userAddress ? orderData.userAddress[0].addressLine2 : ''}<br/>{orderData.userAddress ? orderData.userAddress[0].city : ''} {' '}{orderData.userAddress ? orderData.userAddress[0].zipCode : ''}<br/>{orderData.userAddress ? orderData.userAddress[0].state : ''}<br/>{orderData.userAddress ? orderData.userAddress[0].country : ''}<br/>
                            {/* <b>E-mail:</b> <a href="mailto:divakarvishwamithra@gmail.com" target="_blank">divakarvishwamithra@gmail.com</a><br/> */}
                            <b>Mobile : </b> {orderData.userAddress ? orderData.userAddress[0].phoneNumber : ''}<br/>
                            <b>Order Status : </b> Completed<br/></b></td>
                    </tr>
                </tbody>
            </table>

            
            <table style={{borderCollapse:"collapse",width:"100%",borderTop:"1px solid #dddddd",borderLeft:"1px solid #dddddd",marginBottom:"20px"}}>
                <thead>
                    <tr>
                        <td style={{fontSize:"16px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"left",padding:"7px",color:"#222222"}}>
                            Invoice Details</td>
                        <td style={{fontSize:"16px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"left",padding:"7px",color:"#222222"}}>
                            Billing Address</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td style={{fontSize:"16px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"left",padding:"7px"}}>
                            <b>Invoice Date : </b>{moment(orderData.date).format('DD-MM-YYYY')} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br/>
                            <b>Invoice No. : </b> SUWEB-IN-{getlength(orderData.INVOICEID ? orderData.INVOICEID : null) === 1 ? '000' : getlength(orderData.INVOICEID ? orderData.INVOICEID : null) === 2 ? '00' : getlength(orderData.INVOICEID ? orderData.INVOICEID : null) === 3 ? '0' : orderData.INVOICEID ? orderData.INVOICEID : null}{orderData.INVOICEID ? orderData.INVOICEID : null}<br/>
                            <b>Order No. : </b>SUWEB-OR-{getlength(orderData.orderNo ? orderData.orderNo : null) === 1 ? '000' : getlength(orderData.orderNo ? orderData.orderNo : null) === 2 ? '00' : getlength(orderData.orderNo ? orderData.orderNo : null) === 3 ? '0' : orderData.orderNo ? orderData.orderNo : null}{orderData.orderNo ? orderData.orderNo : null}<br/>
                            {/* <b>Date Added:</b> 2021-10-19 01:06:56.0<br/> */}
                            <b>Payment Method : </b>{orderData.paymentType}<br/>
                            {/* <b>Shipping Method:</b> Reduced Shipping (Weight: 0.05kg) */}
                        </td>
                        <td style={{fontSize:"16px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"left",padding:"7px"}}>
                        {orderData.userAddress ? orderData.userAddress[0].fName : ''}{' '}{orderData.userAddress ? orderData.userAddress[0].lName : ''}<br />{orderData.userAddress ? orderData.userAddress[0].addressLine1 : ''}<br/>{orderData.userAddress ? orderData.userAddress[0].addressLine2 : ''}<br/>{orderData.userAddress ? orderData.userAddress[0].city : ''} {' '}{orderData.userAddress ? orderData.userAddress[0].zipCode : ''}<br/>{orderData.userAddress ? orderData.userAddress[0].state : ''}<br/>{orderData.userAddress ? orderData.userAddress[0].country : ''}<br/>
                            <b>E-mail:</b> <a href={`mailto:${orderData.userEmail}`} target="_blank">{orderData.userEmail}</a><br/>
                            <b>Mobile : </b> {orderData.userAddress ? orderData.userAddress[0].phoneNumber : ''}<br/>
                            </td>
                            
                    </tr>
                </tbody>
            </table>
            <table style={{borderCollapse:"collapse",width:"100%",borderTop:"1px solid #dddddd",borderLeft:"1px solid #dddddd",marginBottom:"20px"}}>
                <thead>
                    <tr>
                    <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"center",padding:"7px",color:"#222222"}}>
                            Sl No.</td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"center",padding:"7px",color:"#222222"}}>
                            Product</td>
                            <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"center",padding:"7px",color:"#222222"}}>
                            Product Id</td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"center",padding:"7px",color:"#222222"}}>
                            Color</td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"center",padding:"7px",color:"#222222"}}>
                        Unit 
                        <br />Price (₹)</td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"center",padding:"7px",color:"#222222"}}>
                            Qty</td>
                            <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"center",padding:"7px",color:"#222222"}}>
                        Tax %</td> 
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"center",padding:"7px",color:"#222222"}}>
                        Taxable <br />Value (₹)</td> 
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"center",padding:"7px",color:"#222222"}}>
                        CGST (₹)</td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"center",padding:"7px",color:"#222222"}}>
                        SGST (₹)</td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"center",padding:"7px",color:"#222222"}}>
                        Tax <br />Amount (₹)</td>
                        {/* <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"right",padding:"7px",color:"#222222"}}>
                            Tax Type</td> */}
                                             
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",backgroundColor:"#efefef",fontWeight:"bold",textAlign:"right",padding:"7px",color:"#222222"}}>
                            Amount (₹)</td>
                    </tr>
                </thead>
                <tbody>
                {orderData.productsList ? orderData.productsList.map((each, index) => {
                    var taxPer = 5;
                    var taxAmount = taxPer === 18 ? 118 : taxPer === 12 ? 112 : taxPer === 5 ? 105 : each.productBasePrice > 999 ? 112 : 105
                    var eachAmount = each.productAmount
                    var taxDisplay = eachAmount / taxAmount * 100 
                    var netAmountDisplay = eachAmount - taxDisplay
                    var cgstAndSgst = netAmountDisplay / 2
                    var restTaxAmount = eachAmount - taxDisplay.toFixed(2)

                    console.log(taxAmount, eachAmount, taxDisplay, netAmountDisplay, cgstAndSgst)
                    // var netAmountDisplay = []
                    return <tr key={index}>
                    <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"center",padding:"7px"}}>
                            {index + 1}</td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"left",padding:"7px"}}>
                            {each.productName} {', '} {each.productArray.length > 0 ? `[${each.productArray[0].size}]` : ''}
                        </td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"center",padding:"7px"}}>
                        {each.productId}</td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"center",padding:"7px"}}>
                            {each.productColor}</td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}}>
                            {each.productArray.length > 0 ? each.productArray[0].price : each.productBasePrice}.00</td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"center",padding:"7px"}}>
                            {each.productQuantity}</td>
                            <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"center",padding:"7px"}}>
                        {taxPer} %</td>
                            <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}}>
                            {taxDisplay.toFixed(2)}</td>
                            <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"center",padding:"7px"}}>
                            {cgstAndSgst.toFixed(2)} (2.5%)</td>
                            <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"center",padding:"7px"}}>
                            {cgstAndSgst.toFixed(2)} (2.5%)</td>
                            <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}}>
                            {netAmountDisplay.toFixed(2)}</td>
                            {/* <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}}>
                            SGST({each.taxPer / 2}%), CGST({each.taxPer / 2}%)</td> */}
                        
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}}>
                        {each.productAmount}.00</td>
                    </tr>
                }) : ''}
                    
                </tbody>

                <tfoot>

                    {/* <tr>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}} colSpan="10"><b>Sub-Total:</b></td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}}>
                            ₹780.95</td>
                    </tr>
                    <tr>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}} colSpan="10"><b>CGST - 2.5%:</b></td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}}>
                            ₹19.52</td>
                    </tr> */}
                    {orderData.productAmount - 50 < 100 ? <tr>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}} colSpan="11"><b>Delivery Charges:</b></td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}}>
                          <b> ₹ 50.00 </b>
                           </td>
                    </tr> : null}
                    <tr>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}} colSpan="11"><b>Total Amount:</b></td>
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}}>
                          <b> ₹ {orderData.productAmount - 50 < 100 ? orderData.productsList.reduce((a, b) =>  a+ b.productAmount , 0) + 50 : orderData.productsList.reduce((a, b) =>  a+ b.productAmount , 0)}.00 </b>
                           </td>
                    </tr>
                    <tr>
                    {/* <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}} colSpan="11"><b>Total amount in words:</b></td> */}
                        <td style={{fontSize:"13px",borderRight:"1px solid #dddddd",borderBottom:"1px solid #dddddd",textAlign:"right",padding:"7px"}} colSpan="12">
                           <b>Amount in words : &nbsp;&nbsp;
                           {convertNumberToWords(orderData.productAmount - 50 < 100 ? orderData.productsList.reduce((a, b) =>  a+ b.productAmount , 0) + 50 : orderData.productsList.reduce((a, b) =>  a+ b.productAmount, 0))}</b> 
                           </td>
                    </tr>
                </tfoot>

            </table> </> : ''}
            <p style={{ textAlign: 'center' }}> Thanks for shopping with us.</p>
        </>
    )
}

export default Invoice