import { useEffect, useState } from 'react'
import { categories } from '../Constants/School'
import { Image } from 'antd'
import ButtonBackCat  from '../Constants/ButtonCat'

function SelectedCategory(props) {
    const [data, setData] = useState([])
    useEffect(() => {
        if (props.match.params.id === 'SCHOOL_UNIFORMS') {
            props.history.push('/schoolslist/' + props.match.params.id)
        } else if (props.match.params.id === 'CUBS_BULBULS') {
            props.history.push('/cubslist/' + props.match.params.id)
        } else if (props.match.params.id === 'LAB_COATS') {
            var data = require(`../Categories/Json/LAB_COATS.json`)
            setData(data)
        } else if (props.match.params.id === 'GRADUATION_GOWNS') {
            var data = require(`../Categories/Json/GRADUATION_GOWNS.json`)
            setData(data)
        } else if (props.match.params.id === 'BLAZERS') {
            var data = require(`../Categories/Json/BLAZERS.json`)
            setData(data)
        } else if (props.match.params.id === 'HOSPITAL_UNIFORMS') {
            var data = require(`../Categories/Json/HOSPITAL_UNIFORMS.json`)
            setData(data)
        } else if (props.match.params.id === 'CORPORATE_UNIFORMS') {
            var data = require(`../Categories/Json/CORPORATE_UNIFORMS.json`)
            setData(data)
        } else if (props.match.params.id === 'HOTEL_UNIFORMS') {
            var data = require(`../Categories/Json/HOTEL_UNIFORMS.json`)
            setData(data)
        } else if (props.match.params.id === 'SPORTS_UNIFORMS') {
            var data = require(`../Categories/Json/SPORTS_UNIFORMS.json`)
            setData(data)
        } else if (props.match.params.id === 'SECURITY_UNIFORMS') {
            var data = require(`../Categories/Json/SECURITY_UNIFORMS.json`)
            setData(data)
        }
        //need to remove once we updated photos
        setData([])
    }, [])


    return (
        <>
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <h1>{props.location.state.name}</h1>
                        </div>
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <ButtonBackCat />
                        </div>
                    </div>
                </div>
            </header>
            <div className="container">
                <div className="row">
                    { data.length > 0 ? data.map((data, index) => {
                        return <div className="col-lg-4" style={{ marginBottom: ' 20px' }} key={index}>
                            <Image
                            width={200}
                            src={data.image}
                            />
                        </div>
                    }) : <div className="col-xl-12" style={{ textAlign: 'center' }}>
                    <h1>{props.location.state.name} data will be updating soon</h1>
                </div>}
                </div>
                { data.length > 0 ?<div className="col-xl-12" style={{ textAlign: 'center' }}>
                    <p style={{color:'green', fontSize:'20px', fontWeight: 'bold'}}>Please contact store to purchase above items, Call us: &nbsp;&nbsp; <a href="tel:+91 95388 31666">+91 95388 31666</a> &nbsp;&nbsp;|&nbsp;&nbsp;<a href="tel:+91 97313 12666">+91 97313 12666</a></p>
                </div> : ''}
            </div>
        </>
    )
}

export default SelectedCategory;