function DeliveryInstructions() {
    return (
        <>
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <h1>Delivery Information</h1>
                        </div>
                    </div>
                </div>
            </header>
            <div className="ex-basic-1 pt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <p style={{ textAlign: 'justify' }}>
                                At <a href="https://smartuniforms.in">Smart Uniforms</a>, we want you to know that your online shopping experience is
                                about quality, pricing and delivery.</p>
                            {/* <h3 className="mb-3">Delivery Timelines</h3> */}
                            <p style={{ textAlign: 'justify' }}>Your online shopping products will have the best shipping partners to reach them in
                                the right Place and right time.</p>
                            <h3 className="mb-3">The main terms and conditions of our shipping policy are as follows.</h3>
                            <p style={{ textAlign: 'justify' }}>
                                Usually the product that is shopping is 3 to 8 working days (depends on
                                address/place). We aim is ready to conifrm the order on the product you have
                                ordered in short time. We will do our best to ensure that the product you have
                                ordered is delivered to you as soon as possible. A beginning of New Academic year
                                months of April and May we have maximum production pressure. In this regards, We
                                urge you to keep your products in demands for Maximum 3 to 4 weeks before arrival
                                and it may be a while before products arrive.
                            </p>

                            <h3 className="mb-3"><u>At a time when you do not have a product stock that you want</u></h3>
                            <p style={{ textAlign: 'justify' }}>
                                We will send you an email giving you a forecast delivery date. If long delays are
                                anticipated, you will have an option to wait until the item is back in stock or cancel
                                and receive a refund. If you have a query that is not covered here, please write to us
                                at mail us <a href="mailto:smartuniforms14@gmail.com"><strong>smartuniforms14@gmail.com</strong></a> or call our customer care helpline
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeliveryInstructions;