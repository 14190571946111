import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons'

function ButtonBackCat() {
    return (
        <div className="col-xl-12" style={{ textAlign: 'center' }}>
            <Link to={`/categories`}>
            <Button type="primary" style={{width: '207px'}} icon={<ArrowLeftOutlined style={{ fontSize: '27px', color :'red' ,position:'absolute', top:'2px', left:'7px'}} />} size="large">
                                <b style={{float:'right', fontSize: '17px'}}>Back to Categories</b>
                </Button>
            </Link>
        </div>
    )
}

export default ButtonBackCat;