import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logoutUser } from '../actions/authActions'
import $ from 'jquery';

function Nav() {
    const user = useSelector(state => state.auth)
    const [isAuthenticated, setAuthenticated] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (user.isAuthenticated) {
            setAuthenticated(true)
        } else {
            setAuthenticated(false)
        }
    })

    const logOutUserFromPanel = () => {
        dispatch(logoutUser())
    }

    const toggler = () => {
        $('.offcanvas-collapse').toggleClass('open')
    }

    return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-light" id="navbarhide">
            <div className="container">
                <a className="navbar-brand logo-image" href="/">
                    <div className='row'>

                        <div className='col'>
                            <img src="../images/logo_new.png" alt="Smart Uniforms" />
                        </div>
                        {/* <div className='col'>
                            <div className='row'>
                                <b style={{ padding: '3px', color: 'darkblue', lineHeight: 'normal' }}>SMART UNIFORMS</b>
                            </div>
                            <div className='row' style={{ float: 'right' }}>
                                <b style={{ color: 'red', fontSize: '.9rem' }}>Wear Your Success</b>
                            </div>
                        </div> */}
                    </div>



                </a>

                <button className="navbar-toggler p-0 border-0" type="button" data-toggle="offcanvas" id="navbarBut" onClick={(e) => toggler(e)}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link page-scroll" href="/">Home <span className="sr-only">(current)</span></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link page-scroll" href="/about">About</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link page-scroll" href="/categories">Categories</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link page-scroll" href="/measureclothes">Measurement</a>
                        </li>
                        {user.user.userRole === "ADMIN" ? <li className="nav-item">
                            <a className="nav-link page-scroll" href="/admin">Admin</a>
                        </li> : ''}
                        {isAuthenticated ? <li className="nav-item">
                            <a className="nav-link page-scroll" href="/user">My Home</a>
                        </li> : <><li className="nav-item">
                            <a className="nav-link page-scroll" href="/register">Register</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link page-scroll" href="/login">Login</a>
                    </li> </>}
                    </ul>
                    {isAuthenticated ? <div>
                        <span className="nav-item">
                            <a className="btn-solid-sm page-scroll" href="/cart">Cart</a>
                        </span>
                        <span className="nav-item">
                            <a className="btn-solid-sm page-scroll" onClick={(e) => logOutUserFromPanel(e)}>Logout</a>
                        </span>
                    </div> : ''}
                </div>
            </div>
        </nav>
    )
}

export default Nav