import { withRouter } from "react-router"
import { useState, useEffect } from "react"
import { Card } from 'antd';
import ButtonBackCat  from '../Constants/ButtonCat'
import { Link } from 'react-router-dom';

const CardData = [{
    name:'Cubs / Scouts Uniforms (Boys)',
    gender:'Boys',
    value:'sBoys',
    cardDescription: 'Cubs / Scouts Uniforms (Boys)',
    image:'../images/products/scouts/coverpic/sc1.jpg'
},{
    name:'BulBuls / Guides Uniforms (Girls)',
    gender:'Girls',
    value:'sGirls',
    cardDescription:'BulBuls / Guides Uniforms (Girls)',
    image:'../images/products/scouts/coverpic/sc2.jpg'
}]

function BulBul(props) {
    const [gender, setGender] = useState('')

    useEffect(() => {
        // console.log(props)
    }, [])
    return (
        <>
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <h1>Cubs & Bulbuls, Scouts & Guides Uniforms</h1>
                        </div>
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <ButtonBackCat />
                        </div>
                    </div>
                </div>
            </header>
            <div className="container">
                <div className="row" style={{margin:'30px'}}>
                    {CardData.map((data, index) => {
                        return <div onClick={(e) => setGender(data.gender)} style={{margin:'0 auto'}}>
                            <Link to={{pathname:`/products`, state: data}}>
                            <Card
                        hoverable
                        style={{ width: '100%' }}
                        cover={<img alt="Smart Uniforms" style={{ margin: '0 auto', height: '220px', width: '240px', marginTop: '10px' }} src={data.image} />}
                    >
                        <p style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', color: 'darkblue' }}>{data.name}</p>
                    </Card>
                    </Link>
                        </div> 
                    }) }
                </div>
            </div>
        </>
    )
}

export default withRouter(BulBul)