import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from "axios"
import { Tabs, Modal, Button, Input, Table, Tag, Space, Tooltip, Steps } from 'antd';
import { EditOutlined, SearchOutlined, DeleteOutlined, DownloadOutlined, UserOutlined, LikeOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined, EyeOutlined } from '@ant-design/icons'
import { openNotificationWithIcon } from '../Constants/Notifications';
import moment from 'moment';
import Highlighter from 'react-highlight-words';

const { TabPane } = Tabs;

const { Step } = Steps;

const errStyle = {
    color: 'red',
    textAlign: 'center',
    fontSize: '.7rem'
}

// const columns = [
//     {
//         title: 'Product Name',
//         dataIndex: 'productName',
//         key: 'productName',
//     },
//     {
//         title: 'Size',
//         dataIndex: 'productArray',
//         key: 'productArray',
//         render: product => (
//             <>
//                 {product.map((p) => {
//                     return <p>{p.size}</p>
//                 })}
//             </>
//         ),
//     },
//     {
//         title: 'Date',
//         dataIndex: 'date',
//         key: 'date',
//         render: date => <p>{getFormatedDate(date)}</p>
//     },
//     {
//         title: 'Color',
//         dataIndex: 'productColor',
//         key: 'productColor',
//     },
//     {
//         title: 'Status',
//         dataIndex: 'STATUS',
//         key: 'STATUS',
//         render: status => <Tag color={status === 'TXN_SUCCESS' ? 'green' : 'red'} key={status}>
//             {status === 'TXN_SUCCESS' ? 'Success' : 'Failure'}
//         </Tag>
//     },
//     {
//         title: 'Image',
//         key: 'productImage',
//         dataIndex: 'productImage',
//         render: prod => <img src={prod} style={{ height: '200px', width: '200px' }}></img>
//     },
// ];

const getFormatedDate = (date) => {
    return moment(date).format('DD-MMM-YYYY HH:mm');
}

function User(props) {
    const user = useSelector(state => state.auth)
    // const errors = useSelector(state => state.errors)
    const [isAuthenticated, setAuthenticated] = useState(false)
    const [userAddress, setUserAddress] = useState([])
    const [myDetails, setMyDetails] = useState({})
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isProdModalVisible, setIsProdModalVisible] = useState(false);
    const [isTrackVisible, setIsTrackVisible] = useState(false);
    const [errors, setErrors] = useState({})
    const [searchText, setSearchText] = useState('')
    const [searchColoumn, setSearchColoumn] = useState('')
    const [productList, setProductList] = useState([])
    const [orderData, setOrderData] = useState([])
    const [address, setAddress] = useState({
        fName: '',
        lName: '',
        phoneNumber: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: 'India',
        zipCode: '',
        id: ''
    })
    const [edit, setEdit] = useState(false);
    const [myProducts, setMyProducts] = useState([])
    const [userComment, setUserComment] = useState('');

    var searchInput

    useEffect(() => {
        if (user.isAuthenticated) {
            setAuthenticated(true)
        } else {
            setAuthenticated(false)
        }
        getAddress()
        getMyDetails()
        getMyProducts()
    }, [])

    const handleProdOk = () => {
        setIsProdModalVisible(false);
      };
    
      const handleProdCancel = () => {
        setIsProdModalVisible(false);
      };

    const getAddress = () => {
        axios.get(`v1/user/getaddress?userId=${user.user.id}`)
            .then(res => setUserAddress(res.data))
            .catch(err => {
                // console.log(err)
            })
    }

    const getMyProducts = () => {
        axios.get(`v1/products/getuserproducts?userId=${user.user.id}`)
            .then(res => setMyProducts(res.data.reverse()))
            .catch(err => {
                // console.log(err)
            })
    }

    const getMyDetails = () => {
        axios.get(`v1/users/me`)
            .then(res => setMyDetails(res.data))
            .catch(err => {
                // console.log(err)
            })
    }

    const callback = (key) => {
        // console.log(key);
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  setSearchText(selectedKeys[0])
                  setSearchColoumn(dataIndex)
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: text =>
          searchColoumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchColoumn(dataIndex)
      };
    
      const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
      };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const productColoums = [
        {
  title: 'Product Name',
  dataIndex: 'productName',
  key: 'productName',
  ...getColumnSearchProps('productName'),
},
{
  title: 'Size',
  dataIndex: 'productArray',
  key: 'productArray',
  // width: 180,
  render: product => (
      <>
          {product.map((p) => {
              return <p>{p.size}</p>
          })}
      </>
  ),
},
{
  title: 'Quantity',
  key: 'productQuantity',
  dataIndex: 'productQuantity',
  // width: 180,
  // render: prod => <img src={prod} style={{ height: '150px', width: '150px' }}></img>
},
{
  title: 'Image',
  key: 'productImage',
  dataIndex: 'productImage',
  // width: 180,
  render: prod => <img src={prod} style={{ height: '150px', width: '150px' }}></img>
},
{
  title: 'Color',
  dataIndex: 'productColor',
  key: 'productColor',
  render: color => <Tag color={color} key={color}>
      {color}
  </Tag>
}
]

const getlength = (number) => {
    if(number === null) {
        return '' 
    } else {
        return number.toString().length;
    }
}

const purchasedColoums = [
    {
        title: 'Order Number',
        dataIndex: 'orderNo',
        key: 'orderNo',
        render: data => `SUWEB-OR-${getlength(data ? data : null) === 1 ? '000' : getlength(data ? data : null) === 2 ? '00' : getlength(data ? data : null) === 3 ? '0' : data ? data : null}${data ? data : null}`,
        // ...getColumnSearchProps('orderNo'),
    },
{
    title: 'Payment Type',
    key: 'paymentType',
    dataIndex: 'paymentType',
    width: 100,
    render: type => <b>{type === "COD" ? "No Payment Done" : type}</b>,
},
{
    title: 'Ordered Date & Time',
    dataIndex: 'date',
    key: 'date',
    render: date => <p>{getFormatedDate(date)}</p>
},
{
    title: 'Amount',
    dataIndex: 'productAmount',
    key: 'productAmount',
    width: 100,
    render: amount => <b>{amount}</b>,
},
{
    title: 'School',
    dataIndex: 'userSchool',
    key: 'userSchool',
    ...getColumnSearchProps('userSchool'),
},
{
    title: 'Payment Status',
    dataIndex: 'STATUS',
    key: 'STATUS',
    render: status => <Tag color={status === 'TXN_SUCCESS' ? 'green' : 'red'} key={status}>
        {status === 'TXN_SUCCESS' ? 'Success' : 'Failure'}
    </Tag>
},
{
    title: 'Products List',
    dataIndex: 'actions',
    key: 'actions',
    fixed: 'right',
    render: (data, index) => {return ( <>
    <Tooltip title="Product Data"><Button type="primary" shape="circle" icon={<DownloadOutlined />} onClick={(e) => showproductData(index)} /></Tooltip> &nbsp;&nbsp;&nbsp;&nbsp;
    {index.STATUS === 'TXN_SUCCESS' ? <Tooltip title="Track Order"><Button type="primary" shape="circle" icon={<EyeOutlined />} onClick={(e) => showProgressToUser(index)} /></Tooltip> : ''}
    </>
    )}
},
]

const showproductData = (data) => {
    setOrderData(data)
    setUserComment(data.customerComment)
    setProductList(data.productsList)
    setIsProdModalVisible(true);
}

const showProgressToUser = (data) => {
    setOrderData(data)
    setIsTrackVisible(true);
}

    const handleOk = () => {
        var obj = {
            userId: user.user.id,
            fName: address.fName,
            lName: address.lName,
            phoneNumber: `${address.phoneNumber}`,
            addressLine1: address.addressLine1,
            addressLine2: address.addressLine2,
            city: address.city,
            state: address.state,
            country: 'India',
            zipCode: `${address.zipCode}`,
            id: address.id,
        }

        if (edit) {
            axios.put(`v1/user/addaddress`, obj)
                .then(res => {
                    openNotificationWithIcon({ type: 'success', msg: 'Address Modified', description: 'Address successfully Modified' })
                    setIsModalVisible(false)
                    getAddress()
                })
                .catch(err => setErrors(err.response.data))
        } else {
            axios.post('v1/user/addaddress', obj)
                .then(res => {
                    openNotificationWithIcon({ type: 'success', msg: 'Address Added', description: 'Address successfully added to your list' })
                    setIsModalVisible(false)
                    getAddress()
                })
                .catch(err => setErrors(err.response.data))
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleChage = (e) => {
        const { id, value } = e.target;
        setAddress(addr => ({ ...addr, [id]: value }));
    }

    const editAddress = (data) => {
        setEdit(true)
        setAddress({
            userId: data.userId,
            fName: data.fName,
            lName: data.lName,
            phoneNumber: data.phoneNumber,
            addressLine1: data.addressLine1,
            addressLine2: data.addressLine2,
            city: data.city,
            state: data.state,
            country: 'India',
            zipCode: data.zipCode,
            id: data._id
        })
        setIsModalVisible(true);
    }

    const deleteAddress = (data) => {
        axios.delete('v1/user/deleteaddress?id=' + data._id)
            .then(res => {
                openNotificationWithIcon({ type: 'error', msg: 'Address Deleted', description: 'Address successfully deleted from your list' })
                getAddress()
            })
            .catch(err => {
                // console.log(err)
            })
    }

    const handleTrackOk = () => {
        setIsTrackVisible(false)
    }

    return (
        <>
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <h1>My Data</h1>
                        </div>
                    </div>
                </div>
            </header>
            <div className="container">
                {/* <div className="row">
                    <div className="col-lg-6">
                    <p className="p-large"># 44, 8th Cross, HBCS Layout <br />Chandra Layout, Police Station Road <br />Near Gurushree Hospital <br />Bangalore – 560040</p>
                    </div>
                    <div className="col-lg-6">
                    <p className="p-small"># 44, 8th Cross, HBCS Layout <br />Chandra Layout, Police Station Road <br />Near Gurushree Hospital <br />Bangalore – 560040</p>
                    </div>
                </div> */}

                <Tabs defaultActiveKey={props.location.state ? props.location.state.key : '3'} onChange={callback} centered>
                    <TabPane tab="My Details" key="1">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mydetails">
                                    <h4><p style={{ color: 'blue' }}>Name  : </p>{myDetails.name}</h4>
                                    <h4><p style={{ color: 'blue' }}>Email : </p>{myDetails.email}</h4>
                                    <h4><p style={{ color: 'blue' }}>Phone : </p><Input
                                        style={{ width: '450px' }}
                                        placeholder={myDetails.phoneNumber}
                                        value={myDetails.phoneNumber}
                                        maxLength={10}
                                    /></h4>
                                    
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="My Address" key="2">
                        {
                            userAddress.length > 0 ?
                                userAddress.map((data, index) => {
                                    return <div>
                                        <div className="row">
                                            <div className="col-lg-6"> <div onClick={(e) => editAddress(data)}> <EditOutlined style={{ fontSize: '25px' }} /> Edit Address</div></div>
                                            <div className="col-lg-6"> <div onClick={(e) => deleteAddress(data)}> <DeleteOutlined style={{ fontSize: '25px' }} /> Delete Address</div></div>
                                        </div>
                                        <p className="p-large" key={index}>
                                            {data.fName}  {data.lName}<br />
                                            {data.addressLine1}, {data.addressLine2} <br />
                                            {data.city}, {data.state} <br />
                                            {data.country} – {data.zipCode} <br /><hr />
                                            {data.phoneNumber}
                                            <hr /></p> </div>

                                }) : <p style={errStyle}>No Address, If you want to add one click Below Button</p>
                        }
                        <div style={{ textAlign: 'center' }}>
                            <Button type="primary" onClick={showModal}>
                                Add Address
                            </Button>
                        </div>
                    </TabPane>
                    <TabPane tab="My Orders" key="3">
                        <Table
                            columns={purchasedColoums}
                            rowKey={record => record._id}
                            dataSource={myProducts}
                        // scroll={{ x: 'calc(700px + 50%)'}}
                        />
                    </TabPane>
                </Tabs>
                <Modal title="Add Address" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <div>
                        <Input type="text" id="fName" onChange={(e) => handleChage(e)} placeholder="First Name" value={address.fName} />
                        <p style={errStyle}>{errors.fName}</p>
                    </div>
                    <div>
                        <Input type="text" id="lName" onChange={(e) => handleChage(e)} placeholder="Last Name" value={address.lName} />
                        <p style={errStyle}>{errors.lName}</p>
                    </div>
                    <div>
                        <Input type="number" id="phoneNumber" onChange={(e) => handleChage(e)} placeholder="Phone Number" value={address.phoneNumber} />
                        <p style={errStyle}>{errors.phoneNumber}</p>
                    </div>
                    <div>
                        <Input type="text" id="addressLine1" onChange={(e) => handleChage(e)} placeholder="Address Line 1" value={address.addressLine1} />
                        <p style={errStyle}>{errors.addressLine1}</p>
                    </div>
                    <div>
                        <Input type="text" id="addressLine2" onChange={(e) => handleChage(e)} placeholder="Address Line 2" value={address.addressLine2} />
                        <p style={errStyle}></p>
                    </div>
                    <div>
                        <Input type="text" id="city" onChange={(e) => handleChage(e)} placeholder="City Name" value={address.city} />
                        <p style={errStyle}>{errors.city}</p>
                    </div>
                    <div>
                        <Input type="text" id="state" onChange={(e) => handleChage(e)} placeholder="State Name" value={address.state} />
                        <p style={errStyle}>{errors.state}</p>
                    </div>
                    <div>
                        <Input type="text" id="country" onChange={(e) => handleChage(e)} placeholder="Country Name" value='India' />
                        <p style={errStyle}>{errors.country}</p>
                    </div>
                    <div>
                        <Input type="number" id="zipCode" onChange={(e) => handleChage(e)} placeholder="Zip Code / Postal Code" value={address.zipCode} />
                        <p style={errStyle}>{errors.zipCode}</p>
                    </div>
                </Modal>
                <>
                <>
                <Modal title="Products Details" visible={isProdModalVisible} onOk={handleProdOk} onCancel={handleProdCancel} width='100%'>
                    <Table
                            columns={productColoums}
                            rowKey={record => record._id}
                            pagination={{ position: ['bottomCenter'], pageSize: 30 }}
                            dataSource={productList} />
                            <div>
                                <p><b>Your Comment : {userComment}</b></p>
                            </div>
                </Modal>
            </>
            <>
            <Modal title="Order Tracker" visible={isTrackVisible} onOk={handleTrackOk} onCancel={handleTrackOk} width='100%'>
            <Steps>
                <Step status={orderData.orderStatus >= 1 ? "finish" : "error"} title="Order Taken" icon={<SolutionOutlined />} />
                <Step status={orderData.orderStatus >= 2 ? "finish" : "error"} title="Verifying Order" icon={<UserOutlined />} />
                <Step status={orderData.orderStatus >= 3 ? "finish" : "error"} title="Under Process" icon={orderData.orderStatus === 4 ? <LikeOutlined /> : <LoadingOutlined />} />
                <Step status={orderData.orderStatus === 4 ? "finish" : "error"} title="Out for Delivery" icon={<SmileOutlined />} />
            </Steps>
            </Modal>
            </>
                </>
            </div>
        </>
    )
}

export default User;