import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons'
import ButtonBackCat from '../Constants/ButtonCat';

function Washing(props) {
    return (
        <>
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <h1>Washing and Caring Instructions</h1>
                        </div>
                    </div>
                    {props.location.state  ? <div className="col-xl-12" style={{ textAlign: 'center' }}> 
                        <Link to={{pathname:'/products', state: props.location.state.data}}>
                            <Button type="primary" style={{width: '207px'}} icon={<ArrowLeftOutlined style={{ fontSize: '27px', color :'red' ,position:'absolute', top:'2px', left:'7px'}} />} size="large">
                                <b style={{float:'right', fontSize: '17px'}}>Back to Products</b>
                            </Button>
                        </Link> </div>: <ButtonBackCat />}
                </div>
            </header>
            {/* <div className="ex-basic-1 pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <img className="img-fluid mt-5 mb-3" src="images/logo_enlarged.jpg" style={{ width: '100%', height: '500px' }} alt="smart uniforms" />
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="ex-basic-1 pt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10">
                        <div>
                                <h2 className="mb-3">1. Uniforms</h2>
                                <ul className="list-unstyled li-space-lg mb-5" style={{ marginLeft: '2rem' }}>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Wash dark colors separately</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Do not soak</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Do not bleach</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Do not dry in direct sunlight</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Iron at low setting</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Do hand or machine wash</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>After wash reverse the garments</strong></div>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-3">2. T- Shirts</h2>
                                <ul className="list-unstyled li-space-lg mb-5" style={{ marginLeft: '2rem' }}>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Hand wash separately in the cold water </strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Use Mild detergent</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Dry flat in shade</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Mild Iron</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Do not bleach</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Avoid direct sun light</strong></div>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-3">3. Sweat Shirts</h2>
                                <ul className="list-unstyled li-space-lg mb-5" style={{ marginLeft: '2rem' }}>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Wash this garment separately before use, if not the excess fur will stick on your other garments </strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Hand wash separately in cold water, after wash, reverse the garment and dry it in shade for more durability of the garments</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Also avoid using strong detergents</strong></div>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-3">4. Socks</h2>
                                <ul className="list-unstyled li-space-lg mb-5" style={{ marginLeft: '2rem' }}>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Hand wash separately in the cold water </strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Do not Iron</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Do not dry in direct sun light</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Cut your foot nails</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Do not pull any thread from the socks</strong></div>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-3">5. Track Pant</h2>
                                <ul className="list-unstyled li-space-lg mb-5" style={{ marginLeft: '2rem' }}>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Hand wash separately in the cold water </strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Use Mild detergent</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Dry flat in shade</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Mild Iron</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Do not bleach</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Avoid direct sun light</strong></div>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-3">6. Tie</h2>
                                <ul className="list-unstyled li-space-lg mb-5" style={{ marginLeft: '2rem' }}>
                                <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Hand wash separately in the cold water </strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Avoid direct sun light</strong></div>
                                    </li>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Do not bleach</strong></div>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h2 className="mb-3">7. Blazer</h2>
                                <ul className="list-unstyled li-space-lg mb-5" style={{ marginLeft: '2rem' }}>
                                    <li className="media">
                                        <i className="fas fa-square"></i>
                                        <div className="media-body"><strong>Some Parents only clean blazers every half term, while others clean them once a week. This depends in part on the fabric of the blazer. Polyester blazers are fairly easy to launder, while wool blazers often require professional dry cleaning </strong></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Washing