import { withRouter, useHistory } from "react-router-dom";
import { studentClass } from "../Constants/School"
import { useState, useEffect } from 'react'
import { Card } from "antd";

function DMCSClasses(props) {
    // console.log(props)
    const [gender, setGender] = useState('')
    let history = useHistory();

    useEffect(() => {
        let gen = props.location.pathname.split('/')[2]
        setGender(gen)
    })

    const selectedClass = (e, selectedClass) => {
        history.push({
            pathname: '/products',
            state: {
                data: props.location.state,
                selectedClass: selectedClass,
                gender: gender
            }
        })
    }
    return (
        <>
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <h1>{props.location.state.cardDescription}</h1>
                        </div>
                        {/* <ButtonBackCat /> */}
                    </div>
                </div>
            </header>
            <div className="container">
                <div className="row" style={{ margin: '30px' }}>
                    {studentClass.map((data, index) => {
                        if (index === 13 && props.location.state.value === 'DMCS_VIDYARANYAPURA' || index === 14 && props.location.state.value === 'DMCS_VIDYARANYAPURA') {
                            return ''
                        } else {
                            return <div className="col-lg-4 col-md-4 col-sm-4" onClick={(e) => selectedClass(e, data)} style={{ marginTop: ' 20px' }} key={index}>
                                <Card
                                    hoverable
                                    style={{ width: '100%' }}
                                    cover={<img alt="Smart Uniforms" style={{ margin: '0 auto', height: '240px', width: '250px', marginTop: '10px' }} src={`https://dummyimage.com/600x400/fff/000&text=${data.name}`} />}
                                >
                                    <p style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', color: 'darkblue' }}>{data.name}</p>
                                </Card>
                            </div>
                        }

                    })}
                </div>
            </div>
        </>
    )
}

export default withRouter(DMCSClasses);