import { categories } from "../Constants/School";
import { Card } from "antd";
import { Link } from "react-router-dom";

// const { Meta } = Card;

const errStyle = {
  color: "blue",
  textAlign: "center",
  fontSize: "1rem",
  padding: "1rem",
};

function Categories() {
  return (
    <>
      <header className="ex-header">
        <div className="container">
          <div className="row">
            <div className="col-xl-12" style={{ textAlign: "center" }}>
              <h1>Categories</h1>
            </div>
          </div>
        </div>
      </header>
      <div className="container">
        <div className="row">
          {categories.map((data, index) => {
            return (
              <div
                className="col-lg-4 col-md-4 col-sm-4"
                style={{ marginTop: " 20px" }}
                key={index}
              >
                <Link
                  to={{ pathname: `/selectedcat/${data.value}`, state: data }}
                >
                  <Card
                    hoverable
                    style={{ width: "100%" }}
                    cover={
                      <img
                        alt="Smart Uniforms"
                        style={{
                          margin: "0 auto",
                          height: "220px",
                          width: "240px",
                          marginTop: "10px",
                        }}
                        src={data.image}
                      />
                    }
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "darkblue",
                      }}
                    >
                      {data.name}
                    </p>
                    {data.sName ? (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "darkblue",
                        }}
                      >
                        {data.sName}
                      </p>
                    ) : (
                      ""
                    )}
                  </Card>
                </Link>
              </div>
            );
          })}
          {/* <p style={errStyle}> Due to some busy schedule, we are not accepting online orders. Please visit the store for uniforms, but sure will come back to online shopping soon, Thank You!</p>
                    <p style={errStyle}>ಕೆಲವು ಬಿಡುವಿಲ್ಲದ ವೇಳಾಪಟ್ಟಿಯ ಕಾರಣ, ನಾವು ಆನ್‌ಲೈನ್ ಆರ್ಡರ್‌ಗಳನ್ನು ಸ್ವೀಕರಿಸುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ಸಮವಸ್ತ್ರಕ್ಕಾಗಿ ಅಂಗಡಿಗೆ ಭೇಟಿ ನೀಡಿ, ಆದರೆ ಖಚಿತವಾಗಿ ಶೀಘ್ರದಲ್ಲೇ ಆನ್‌ಲೈನ್ ಶಾಪಿಂಗ್‌ಗೆ ಹಿಂತಿರುಗುತ್ತದೆ, ಧನ್ಯವಾದಗಳು!</p>
                    <div style={{margin:'0 auto'}}>
                        <p style={{color:'red',
    textAlign: 'center',
    fontSize: '1rem',
    padding: '1rem'}}>Please find the shop address here, or click on the map icon to redirect to our shop.</p>
                        <p style={{textAlign: 'center'}}><a href="https://www.google.co.in/maps/dir/12.9794048,77.594624/smart+uniforms/@12.9729171,77.5440314,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae3e79e7245c0d:0x1a45611b7bdb0dc1!2m2!1d77.5271729!2d12.9595885"><i className="material-icons" style={{ fontSize: '48px', color: 'red', }}>place</i></a></p>
                        <p className="p-small" style={{textAlign:'center'}}># 44, 8th Cross, HBCS Layout <br />Chandra Layout, Police Station Road <br />Near Gurushree Hospital <br />Bangalore – 560040</p>
                    </div> */}
        </div>
      </div>
    </>
  );
}

export default Categories;
