import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from "axios"
import { Table, Tag, Space, Tabs, Popover, Input, Button, Tooltip, Modal, DatePicker, Select } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom'
import { PlayCircleOutlined, SearchOutlined, BookOutlined, HighlightOutlined, DownloadOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words';
import { openNotificationWithIcon } from '../Constants/Notifications'

const { Option } = Select;

const { RangePicker } = DatePicker;

const { TabPane } = Tabs;

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: text => <a>{text}</a>,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Mobile',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
    },
    {
        title: 'Student Name',
        dataIndex: 'studentName',
        key: 'studentName',
    },
    {
        title: 'Gender',
        dataIndex: 'studentGender',
        key: 'studentGender',
    },
    {
        title: 'School',
        key: 'school',
        dataIndex: 'school'
    },
    {
        title: 'Class',
        dataIndex: 'studentClass',
        key: 'studentClass',
    },
    {
        title: 'Branch',
        dataIndex: 'studentBranch',
        key: 'studentBranch',
    },
    {
        title: 'Date & Time',
        dataIndex: 'date',
        key: 'date',
        render: data => getFormatedDate(data)
    },
];

const getFormatedDate = (date) => {
    return moment(date).format('DD-MMM-YYYY HH:mm');
}

function Admin() {
    const user = useSelector(state => state.auth)
    const [isAuthenticated, setAuthenticated] = useState(false)
    const [users, setUsers] = useState([])
    const [products, setProducts] = useState([])
    const [searchText, setSearchText] = useState('')
    const [searchColoumn, setSearchColoumn] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [productList, setProductList] = useState([])
    const [userComment, setUserComment] = useState('');
    const [updatedData, setUpdatedData] = useState([]);
    const [orderUpdate, setOrderUpdate] = useState('');
    // const [mailSent, setMailSent] = useState(false);
    // const [searchInput, setSearchInput] = useState('')
    var searchInput

    useEffect(() => {
        document.getElementById('navbarhide').style.display = 'block'
        document.getElementById('footerhide').style.display = 'block'

        if (user.user.userRole !== 'ADMIN') {
            window.location.href = '/home'
            return
        }

        if (user.isAuthenticated) {
            setAuthenticated(true)
        } else {
            setAuthenticated(false)
        }

        getUsers()
        getLatestOrders()
    }, [])

    const getUsers = () => {
        axios.get('v1/users/getusers')
            .then(res => 
                {   
                    console.log(res.data)
                    setUsers(res.data.reverse())}
                )
            .catch(err => {
                // console.log(err)
            })
    }

    const getLatestOrders = () => {
        axios.get('v1/products/getallproducts')
            .then(res => {
                // console.log(res)
                setProducts(res.data.reverse()) //res.data.filter = prod => prod.STATUS === 'TXN_SUCCESS'
            })
            .catch(err => {
                // console.log(err)
            })
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              {/* <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  setSearchText(selectedKeys[0])
                  setSearchColoumn(dataIndex)
                }}
              >
                Filter
              </Button> */}
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.select(), 100);
          }
        },
        render: text =>
          searchColoumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0])
        setSearchColoumn(dataIndex)
      };
    
      const handleReset = clearFilters => {
        clearFilters();
        setSearchText('')
      };


      const getlength = (number) => {
        if(number === null) {
            return '' 
        } else {
            return number.toString().length;
        }
    }

      const purchasedColoums = [
        {
            title: 'Customer Name',
            dataIndex: 'userName',
            key: 'userName',
            ...getColumnSearchProps('userName'),
        },
    {
        title: 'Mobile',
        dataIndex: 'userPhone',
        key: 'userPhone',
    },
    {
        title: 'Address',
        dataIndex: 'userAddress',
        key: 'userAddress',
        render: address => (
            <>
                {address.map((p) => {
                    return <>
                        <p>{p.addressLine1}</p>
                        <p>{p.addressLine2}</p>
                        <p>{p.city}</p>
                        <p>{p.zipCode}</p>
                    </>
                })}
            </>
        ),
    },
    {
        title: 'Payment Type',
        key: 'paymentType',
        dataIndex: 'paymentType',
        width: 100,
        render: type => <b>{type}</b>,
    },
    {
        title: 'Ordered Date',
        dataIndex: 'date',
        key: 'date',
        render: date => <p>{getFormatedDate(date)}</p>
    },
    {
        title: 'Amout',
        dataIndex: 'productAmount',
        key: 'productAmount',
        width: 100,
        render: amount => <b>{amount}</b>,
    },
    {
        title: 'School',
        dataIndex: 'userSchool',
        key: 'userSchool',
        ...getColumnSearchProps('userSchool'),
    },
    {
        title: 'Status',
        dataIndex: 'STATUS',
        key: 'STATUS',
        render: status => <Tag color={status === 'TXN_SUCCESS' ? 'green' : 'red'} key={status}>
            {status === 'TXN_SUCCESS' ? 'Success' : 'Failure'}
        </Tag>
    },
    {
        title: 'Order No',
        dataIndex: 'orderNo',
        key: 'orderNo',
        render: data => `SUWEB-OR-${getlength(data ? data : null) === 1 ? '000' : getlength(data ? data : null) === 2 ? '00' : getlength(data ? data : null) === 3 ? '0' : data ? data : null}${data ? data : null}`
    },
    {
        title: 'Action',
        dataIndex: 'actions',
        key: 'actions',
        fixed: 'right',
        render: (data, index) => {return ( <>
        <Popover content={(<>{index.ORDERID}</>)} placement="rightTop" title="Transaction Id"><Tooltip title="Transaction Id"> <PlayCircleOutlined /> </Tooltip> </Popover>&nbsp;&nbsp;&nbsp;
    {index.STATUS === 'TXN_SUCCESS' ? <>
        <Tooltip title="Invoice"><Link to={{ pathname: '/invoice/' + index._id}} target="_blank"><BookOutlined /></Link></Tooltip> &nbsp;&nbsp;&nbsp;
        {index.mailSent  ? '' : <Tooltip title="Send order Status to user"> <Button type="primary" shape="circle" icon={<HighlightOutlined />} onClick={(e) => remaindingToUser(index)} /></Tooltip> }&nbsp;&nbsp;&nbsp;
        <Tooltip title="Product Data"><Button type="primary" shape="circle" icon={<DownloadOutlined />} onClick={(e) => showproductData(index)} /></Tooltip> &nbsp;&nbsp;&nbsp;</> : ''}
        </>
        )}
    },
    ]

    const productColoums = [
    {
        title: 'Product Name',
        dataIndex: 'productName',
        key: 'productName',
        ...getColumnSearchProps('productName'),
    },
    {
        title: 'Product Id',
        dataIndex: 'productId',
        key: 'productId',
        ...getColumnSearchProps('productId'),
    },
    {
        title: 'Size',
        dataIndex: 'productArray',
        key: 'productArray',
        // width: 180,
        render: product => (
            <>
                {product.map((p) => {
                    return <p>{p.size}</p>
                })}
            </>
        ),
    },
    {
        title: 'Quantity',
        key: 'productQuantity',
        dataIndex: 'productQuantity',
        // width: 180,
        // render: prod => <img src={prod} style={{ height: '150px', width: '150px' }}></img>
    },
    {
        title: 'Image',
        key: 'productImage',
        dataIndex: 'productImage',
        // width: 180,
        render: prod => <img src={prod} style={{ height: '150px', width: '150px' }}></img>
    },
    {
        title: 'Color',
        dataIndex: 'productColor',
        key: 'productColor',
        render: color => <Tag color={color} key={color}>
            {color}
        </Tag>
    }
    ]
    const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };

    const showproductData = (data) => {
        setUserComment(data.customerComment)
        setOrderUpdate(data.orderStatus)
        setProductList(data.productsList)
        setUpdatedData(data)
        setIsModalVisible(true);

    }

    function onDateChange(date) {
        console.log(date);
        if(date === null) {
            getLatestOrders()
        } else {
            axios.get('../v1/products/getproductsdatewise/',{
                params: {
                  gte: new Date(date[0]).toISOString(),
                  lte: new Date(date[1]).toISOString()
                }
              })
            .then(res => {
                setProducts(res.data.reverse())
            })
            .catch(err => {
                // console.log(err)
            });
        }
      }

      const onSingleDateChange = (date) => {
        if(date === null) {
            getLatestOrders()
        } else {
            axios.get('../v1/products/getproductssingledatewise/',{
                params: {
                  date: new Date(date).toISOString()
                }
              })
            .then(res => {
                setProducts(res.data.reverse())
            })
            .catch(err => {
                // console.log(err)
            });
        }
      }

    const remaindingToUser = (data) => {
        axios.post('../v1/products/remaindinguser/', data)
        .then(res => {
            // setMailSent(true)
            openNotificationWithIcon({ type: 'success', msg: 'Mail Sent', description: 'Email Successfully Sent.!' })
            getLatestOrders()
        })
        .catch(err => {
            // console.log(err)
        })
    }

    const handleChange = (value) => {
        if(value === 'GET_BOTH') {
            getLatestOrders()
        } else {
            axios.get('../v1/products/getproductstxn/',{
                params: {
                    STATUS : value
                }
              })
            .then(res => {
                setProducts(res.data.reverse())
            })
            .catch(err => {
                // console.log(err)
            });
        }
    }

    const handleOrderStatusChange = (value) => {
        axios.post('../v1/products/updateorderstatus', {
                id:updatedData._id,
                orderStatus : value
            })
        .then(res => {
            openNotificationWithIcon({ type: 'success', msg: 'Status Update', description: res.data })
            getLatestOrders()
        })
        .catch(err => {
            console.log(err)
        })
    }

    return (
        <>
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <h1>Admin Panel</h1>
                        </div>
                    </div>
                </div>
            </header>
            <div>
                <Tabs defaultActiveKey="2" centered size="large">
                    <TabPane tab="Users Data" key="1">
                        <Table
                            columns={columns}
                            rowKey={record => record._id}
                            pagination={{ position: ['bottomCenter'], pageSize: 30 }}
                            dataSource={users}
                            scroll={{ x: 'calc(700px + 50%)'}} />
                    </TabPane>
                    <TabPane tab="Latest Orders" key="2">
                    <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4" style={{textAlign: 'center', marginBottom:'50px'}}>
                        <p style={{color:'red'}}>Please select from date and to date to filter</p>
                        {/* <DatePicker onChange={onDateChange} placeholder='Please select date here' size="large" style={{ width:'350px'}} /> */}
                        <RangePicker
                            onChange={onDateChange} size="large" style={{ width:'350px'}}
                        />
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <p style={{color:'red'}}>Please select date to filter</p>
                        <DatePicker onChange={onSingleDateChange} placeholder='Please select date here' size="large" style={{ width:'350px'}} />
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4" style={{textAlign: 'center', marginBottom:'50px'}}>
                    <p style={{color:'red'}}>Please select transactions status to filter</p>
                    <Select style={{ width: 450 }} size="large" placeholder="Please Select Transaction Status" onChange={handleChange}>
                        <Option value="Please Select Below" disabled>Please Select Below</Option>
                        <Option value="GET_BOTH">Get Both</Option>
                        <Option value="TXN_SUCCESS">Success</Option>
                        <Option value="TXN_FAILURE">Failure</Option>
                    </Select>
                    </div>
                    </div>
                        <Table
                            columns={purchasedColoums}
                            rowKey={record => record._id}
                            pagination={{ position: ['bottomCenter'], pageSize: 30 }}
                            dataSource={products}
                            scroll={{ x: 'calc(700px + 50%)'}} />
                    </TabPane>
                </Tabs>
            </div>
            <>
                <Modal title="Products Details" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width='100%'>
                    <Table
                            columns={productColoums}
                            rowKey={record => record._id}
                            pagination={{ position: ['bottomCenter'], pageSize: 30 }}
                            dataSource={productList} />
                    <div className="row">
                        <div className="col">
                            <p><b>User Comment : {userComment}</b></p>
                        </div>  
                        <div className="col">
                            <Select style={{ width: 450 }} size="large" placeholder="Please Select Order Status" onChange={handleOrderStatusChange}>
                                <Option value="Please Select Below" disabled>Please Select Below</Option>
                                <Option value="1" disabled={orderUpdate > 1 ? true : false}>Order Taken</Option>
                                <Option value="2" disabled={orderUpdate > 2 ? true : false}>Verifying</Option>
                                <Option value="3" disabled={orderUpdate > 3 ? true : false}>Under Process</Option>
                                <Option value="4" disabled={orderUpdate >= 4 ? true : false}>Out for Delivery</Option>
                            </Select>
                        </div>  
                        
                    </div>

                </Modal>
            </>
        </>
    )
}

export default Admin;