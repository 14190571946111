import { useEffect, useState } from 'react'
import { Card } from 'antd';
import { schools } from '../Constants/School'
import { Link, withRouter } from 'react-router-dom';
import ButtonBackCat from '../Constants/ButtonCat';

const { Meta } = Card;

function SchoolsList(props) {
    const [list, setList] = useState([])

    useEffect(() => {
        // console.log(props)
    },[])
    return (
        <>
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <h1>Schools</h1>
                        </div>
                        <ButtonBackCat />
                    </div>
                </div>
            </header>
            <div className="container">
                <div className="row">
                    {schools.map((data, index) => {
                        if(index === 0) {
                            
                        } else {
                            return <div className="col-lg-4" style={{ marginTop: ' 20px' }} key={index}>
                            <Link to={{pathname:`/products`, state: data}}>
                                <Card
                                    hoverable
                                    style={{ width: '100%' }}
                                    cover={<img alt="Smart Uniforms" style={{ margin: '0 auto',height: '220px', width: '240px', marginTop: '10px'}} src={data.schoolLogo} />}
                                >
                                    <Meta title={data.cardName} style={{ textAlign: 'center' }} description={data.cardDescription} />
                                </Card>
                            </Link>
                        </div>
                        }
                    })}
                </div>
            </div>
        </>
    )
}

export default withRouter(SchoolsList);