import axios from 'axios'
import {useState, useEffect} from 'react'
import swal from 'sweetalert';

const errStyle = {
    color: 'red',
    textAlign: 'center',
    fontSize: '.7rem'
}

function ForgotPassword (props) {
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const getParameterByName = () => {
        let url = window.location.href.split('/')
        return url[url.length - 1]
    }

    useEffect(() => {
        // console.log(getParameterByName())
    })

    const handleChage = (e) => {
        const { id, value } = e.target;
        setPassword(value)
    }

    const submitPassword = (e) => {
        if(password.length <= 5) {
            setPasswordError('Please enter valid password')
        } else {
                axios.post('../v1/users/updatepassword',{ 
                    resetpassword:getParameterByName(),
                    password:password
                })
                .then(res => 
                    {
                        // console.log(res)
                        swal("Success", "Your password is changed successfully, redirecting to login page in 3 seconds", "success");
                        setTimeout(() => {
                            window.location.href ='../login'
                        }, 3000);
                    })
                .catch(err => {
                    // console.log(err)
                })
        }
    }

    return (
        <div>
            <header id="header" className="header">
                <div className="container">
                    <div className="row" style={{textAlign:'center'}}>
                        <div className="col-lg-12">
                            <div className="text-container">
                                <h1 className="h1-large">Please Enter Your New Password</h1>
                            </div> 
                        </div> 
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            <div className="image-container">
                                <div className="form-group">
                                    <input type="password" onChange={(e) => handleChage(e)} className="form-control-input notEmpty" id="password" />
                                    <label className="label-control" htmlFor="password">Password</label>
                                    <p style={errStyle}>{passwordError}</p>
                                </div>
                            </div> 
                            <div className="form-group">
                                <button type="submit" onClick={(e) => submitPassword(e)} className="form-control-submit-button">Reset Password</button>
                            </div>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default ForgotPassword