function Footer() {
  return (
    <div className="footer" id="footerhide">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="footer-col first">
              <h6>Meet Us</h6>{" "}
              <a href="https://www.google.co.in/maps/dir/12.9794048,77.594624/smart+uniforms/@12.9729171,77.5440314,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3bae3e79e7245c0d:0x1a45611b7bdb0dc1!2m2!1d77.5271729!2d12.9595885">
                <i
                  className="material-icons"
                  style={{ fontSize: "48px", color: "red" }}
                >
                  place
                </i>
              </a>
              <p className="p-small">
                # 44, 8th Cross, HBCS Layout <br />
                Chandra Layout, Police Station Road <br />
                Near Gurushree Hospital <br />
                Bangalore – 560040
              </p>
            </div>
            <div className="footer-col second">
              <h6>Links</h6>
              <ul className="list-unstyled li-space-lg p-small">
                <li>
                  Call us:{" "}
                  <a href="tel:+91 95388 31666">
                    <b>(+91) 95 3883 1666</b>
                  </a>{" "}
                  |{" "}
                  <a href="tel:+91 97313 12666">
                    <b>(+91) 97 3131 2666</b>
                  </a>
                </li>
                {/* <li>Important: <a href="terms.html">Terms & Conditions</a>, <a href="privacy.html">Privacy Policy</a></li> */}
                <li>
                  Useful: <a href="/measureclothes/belt">How to Measure</a>,{" "}
                  <a href="/washingandcaring">Washing and Caring</a>
                </li>
                <li>
                  Menu:{" "}
                  <a className="page-scroll" href="/">
                    Home
                  </a>
                  ,{" "}
                  <a className="page-scroll" href="/categories">
                    Categories
                  </a>
                  ,{" "}
                  <a className="page-scroll" href="/about">
                    About
                  </a>
                </li>
                <li>
                  Important:{" "}
                  <a className="page-scroll" href="/privacy">
                    Privacy Policy
                  </a>
                  ,{" "}
                  <a className="page-scroll" href="/termsandconditions">
                    Terms & Conditions
                  </a>
                  ,{" "}
                  <a className="page-scroll" href="/deliveryinfo">
                    Delivery Information
                  </a>
                  ,{" "}
                  <a className="page-scroll" href="/exchangepolicy">
                    Exchange Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-col third">
              <span className="fa-stack">
                <a href="https://api.whatsapp.com/send?phone=919538831666">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i
                    className="fab fa-whatsapp fa-stack-1x"
                    style={{ color: "green" }}
                  ></i>
                </a>
              </span>
              <span className="fa-stack">
                <a href="https://www.facebook.com/profile.php?id=100073208875621">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i
                    className="fab fa-facebook-f fa-stack-1x"
                    style={{ color: "blue" }}
                  ></i>
                </a>
              </span>
              <span className="fa-stack">
                <a href="https://twitter.com/smartuniforms14/">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i
                    className="fab fa-twitter fa-stack-1x"
                    style={{ color: "skyblue" }}
                  ></i>
                </a>
              </span>
              {/* <span className="fa-stack">
                            <a href="#your-link">
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fab fa-pinterest-p fa-stack-1x"></i>
                            </a>
                        </span> */}
              <span className="fa-stack">
                <a href="https://www.instagram.com/smartuniforms14/">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <p className="instagram">
                    <i
                      className="fab fa-instagram"
                      style={{
                        position: "absolute",
                        left: "9px",
                        bottom: "6px",
                      }}
                    ></i>
                  </p>
                </a>
              </span>
              <p className="p-small">
                We would love to hear from you{" "}
                <a href="mailto:smartuniforms14@gmail.com">
                  <strong>smartuniforms14@gmail.com</strong>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
