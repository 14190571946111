import { useEffect, useRef  } from "react"


function Payment (props) {
        const formRef = useRef(null);
        const params = props.location.state.data.data;
        useEffect(() => {
          formRef.current.submit();
        }, []);

    return (
        <div style={{marginTop:'80px'}}>
        <center><h1>Please do not refresh this page...</h1></center>
        <form ref={formRef} method="POST" action={props.location.state.data.url}>
            {Object.keys(params).map(name => (
                <input
                type="hidden"
                name={name}
                value={params[name]}
                ></input>
            ))}
      <input type='hidden' name='CHECKSUMHASH' value={props.location.state.data.checksum}></input>
    </form>
        </div>
    )
}

export default Payment