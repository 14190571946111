function ExchangePolicy() {
    return (
        <>
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <h1>Exchange Policy</h1>
                        </div>
                    </div>
                </div>
            </header>
            <div className="ex-basic-1 pt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <p style={{ textAlign: 'center' }}>
                                THERE SHALL BE <b>NO EXCHANGE OF ANY GARMENT ORDERED</b> AND DESPATCHED FROM OUR STORE.
                            </p>
                            <p style={{ textAlign: 'justify' }}><b>Similarly, there is some possibility to <u>ALTER</u> the garment to the extent possible so as to make it a good fit for your child.</b></p>
                            <p style={{ textAlign: 'justify' }}>At <a href="https://smartuniforms.in">https://smartuniforms.in</a> 
                            <ul>
                                <li>We accept exchanges within 8 days of purchase.</li>
                                <li>You can exchange your purchases garments for <b>“Size Differences or Defects only”</b> and it can be made by calling 9538831666/9731312666 and booking a returns. Any replacement for size require can be stated during the call.</li>
                                <li>We follow a <b>“No Refunds”</b> policy.</li>
                                <li>We request you to make your orders carefully through online. For an exchange, the product must be in its original saleable conditions.</li>
                                <li>To ensure the best quality of our stocks reaches you always, any <b>White Uniforms</b> and <b>Innerwear shall not be exchanged</b> in any circumstances. You are advised to be completely sure of the sizes for these garments products before you buy.</li>
                                <li><b>Customized or Personalized & made to order Items</b> cannot be <b>“Exchanged or Cancelled”</b> once they have entered the production stage or have been dispatched to you.</li>
                                <li>While we try hard and ensure all products to be defect free, however, if any of our products is Damaged or Defective, we have no problems in acknowledging it and replacing it for you.</li>
                                <li>If you received the product, it will be eligible for replacement, only in cases where there are Defects found with the product. We understand and are happy to exchange, replace or repair any such damaged or defective goods for you but we hope our customers respect the intentions behind the policy and don’t abuse it. Following shall not be eligible for return or replacement:</li>
                            </ul>
                            </p>
                            <p style={{textAlign: 'justify',fontWeight: 'bold', paddingLeft: '66px'}}>“Products with damaged or missing labels”, “Damages from product misuse”, “any Damage or Defect which are not covered under the manufacturer’s warranty”, “any product that is returned without all original packaging and accessories, including the box/wrappers, manufacturer’s packaging if any, and all other items originally included with the products delivered”.</p>
                            <h5 className="mb-3">WRONG GOODS DELIVERED</h5>
                            <p style={{ textAlign: 'justify' }}>
                            In the unlikely event that you receive School Uniform items which are not in any material respect what you ordered or are of a materially different quantity to that stated on your order form, we shall make good any such shortage or non-delivery, provided that you notify us of the problem within 3 working days of delivery of the items in question and, if we request you to, return the items in question to us.    
                            </p>
                            <p style={{ textAlign: 'justify' }}>
                            To exchange an item with some defect, send us a mail at <a href="mailto:smartuniforms14@gmail.com"><strong>smartuniforms14@gmail.com</strong></a> with subject as <b>“Defective or Incorrect Product Return”</b> or call us on our helpline number (9538831666/9731312666) which remains open 10:00 am to 6:00 pm from Monday to Saturday.
                            </p>
                            <h5 className="mb-3">When and how can I change/edit an order?</h5>
                            <p style={{ textAlign: 'justify' }}>
                               <ul>
                                   <li>You cannot change/edit a confirmed order. The order will be deemed confirmed when we receive intimation of payment credit from our Payment gateway.</li>
                                    <li><b>Strictly, cancellations or refunds will not be permitted.</b></li>
                                    <li>Once your order payment has been cleared, you cannot make any more changes and the order shall be deemed to be confirmed.</li>
                                    <li>All confirmed orders shall not be cancelled or refunded.</li>
                               </ul>
                            </p>
                            <h5 className="mb-3">How to Ship for an Exchange</h5>
                            <p style={{ textAlign: 'justify' }}> To exchange an item, foremost, send us a mail at <a href="mailto:smartuniforms14@gmail.com"><strong>smartuniforms14@gmail.com</strong></a> with subject as <b>“Product Exchange”</b> or call us on our helpline number (9538831666/9731312666) which remains open 10:00 am to 6:00 pm from Monday to Saturday</p>
                            <p style={{ textAlign: 'justify'}}><b>Please Note: Any items that show signs of wear or have been altered, resized or damaged cannot be accepted for exchange.</b></p>
                            <p style={{ textAlign: 'justify' }}>To enable us to handle your exchange as quickly as possible, please follow the simple instructions below:</p>
                            <p style={{ textAlign: 'justify' }}><b>a. </b>Return the school uniform item using the delivery form included with your delivery giving the reason for the exchange. If you do not have the original delivery form, please include:</p>
                            <ul>
                                <li>Your name and address.</li>
                                <li>Your order number.</li>
                                <li>Your email address.</li>
                                <li>Details of the reason for the exchange.</li>
                            </ul>
                            <p style={{ textAlign: 'justify' }}> <b>b.</b> Wrap the school uniform item securely. Keep your posting receipts in a safe place as we cannot be held responsible for school uniform items that fail to reach us. We are unable to refund any amounts you have paid for shipping it back currently.</p>
                            <p><b>c.</b> We will notify you via email/call about the status of your exchange request. This may take up to <b>3 working days</b> from our receipt of the returned item. Once your returned item is received and accepted, your new item dispatch will be processed immediately.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExchangePolicy;