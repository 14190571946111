import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { openNotificationWithIcon } from "./Constants/Notifications";
import axios from "axios";
import { Modal, Table, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import ButtonBackCat from "./Constants/ButtonCat";
import swal from "sweetalert";
import { Link } from "react-router-dom";

const textStyle = {
  margin: "0 auto",
  fontWeight: "bolder",
  fontSize: "20px",
  color: "red",
};

var addressData = [];

const addressHeader = [
  {
    title: "Name",
    dataIndex: "fName",
  },
  {
    title: "Address",
    dataIndex: "addressLine1",
  },
  {
    title: "City",
    dataIndex: "city",
  },
  {
    title: "Pin Code",
    dataIndex: "zipCode",
  },
  {
    title: "Mobile",
    dataIndex: "phoneNumber",
  },
];

function Cart(props) {
  const user = useSelector((state) => state.auth);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [cartProduct, setCartProduct] = useState([]);
  const [amount, setAmount] = useState("");
  const [addressModal, setAddressModal] = useState(false);
  const [selectionType, setSelectionType] = useState("radio");
  const [userAddress, setUserAddress] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [placeAll, setPlaceAll] = useState(false);
  const [add, setAdd] = useState(false);
  const [addErr, setAddErr] = useState("");

  useEffect(() => {
    if (user.isAuthenticated) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
    getUserCartData();
    getAddress();
  }, []);

  const getAddress = () => {
    axios
      .get(`v1/user/getaddress?userId=${user.user.id}`)
      .then((res) => setUserAddress(res.data))
      .catch((err) => console.log(err));
  };

  const getCountAmount = (data) => {
    setAmount(data.reduce((a, b) => (a = a + b.productAmount), 0));
  };

  const getUserCartData = () => {
    axios
      .get(`v1/carts/getusercart?userId=${user.user.id}`)
      .then((res) => {
        setCartProduct(res.data);
        getCountAmount(res.data);
      })
      .catch((err) => console.log(err));
  };

  const deleteFromCart = (productId, from) => {
    axios
      .delete(`v1/carts/deletefromcart?prodId=${productId}`)
      .then((res) => {
        if (from !== "FINAL") {
          openNotificationWithIcon({
            type: "error",
            msg: "Item Deleted",
            description: "Item successfully deleted from your cart",
          });
        }
        getUserCartData();
      })
      .catch((err) => console.log(err));
  };

  const proceedToCheckOut = (product) => {
    setSelectedProduct(product);
    showModal();
  };

  const checkOutAll = () => {
    setPlaceAll(true);
    showModal();
    // console.log(cartProduct)
  };

  //address selection

  const showModal = () => {
    setAddressModal(true);
  };

  const handleOk = () => {
    if (add) {
      if (placeAll) {
        swal("Enter Comments If Any", {
          content: "input",
        }).then((value) => {
          var obj = {
            userId: cartProduct[0].userId,
            userName: user.user.name,
            userEmail: user.user.email,
            userPhone: user.user.phone,
            productAmount: amount < 100 ? amount + 50 : amount,
            userAddress: addressData,
            productsList: cartProduct,
            paymentType: "COD",
            customerComment: value,
            userSchool: user.user.school,
          };
          axios
            .post("v1/products/purchaseproduct", obj)
            .then((res) => {
              // console.log(res)
              openNotificationWithIcon({
                type: "success",
                msg: "Item Added",
                description:
                  "will place this order... redirecting to payment gateway!",
              });
              {
                /* deleteFromCart(cartProduct[i]._id, 'FINAL') */
              }
              cartProduct.map((data) => deleteFromCart(data._id, "FINAL"));
              props.history.push({
                pathname: "/payment",
                state: {
                  data: res.data,
                },
              });
            })
            .catch((err) => {
              // console.log(err)
            });
          setAddressModal(false);
        });
      } else {
        swal("Enter Comments If Any", {
          content: "input",
        }).then((value) => {
          var obj = {
            userId: selectedProduct.userId,
            productsList: cartProduct,
            userName: user.user.name,
            userEmail: user.user.email,
            userPhone: user.user.phone,
            userAddress: addressData,
            customerComment: value,
            productAmount: amount < 100 ? amount + 50 : amount,
            paymentType: "COD",
            userSchool: user.user.school,
          };
          if (obj.userAddress.length > 0) {
            axios
              .post("v1/products/purchaseproduct", obj)
              .then((res) => {
                // console.log(res)
                openNotificationWithIcon({
                  type: "success",
                  msg: "Item Added",
                  description:
                    "will place this order... redirecting to payment gateway!",
                });
                deleteFromCart(selectedProduct._id, "FINAL");
                props.history.push({
                  pathname: "/payment",
                  state: {
                    data: res.data,
                  },
                });
              })
              .catch((err) => {
                // console.log(err)
              });
            setAddressModal(false);
          }
          //    setAddressModal(false);
        });

        setAdd(false);
      }
    } else {
      setAddErr("Please select one address");
    }
  };

  const handleCancel = () => {
    setAddressModal(false);
  };

  const rowSelection = {
    onChange: (selectedRowKey, selectedRows) => {
      addressData = selectedRows;
      setAdd(true);
      setAddErr("");
    },
  };

  const changeQuantity = (type, product) => {
    // console.log(type, product)
    if (product.productQuantity > 0) {
      for (let i = 0; i < cartProduct.length; i++) {
        if (product.productId === cartProduct[i].productId) {
          if (type === "PLUS") {
            if (product.productArray.length > 0) {
              let amount =
                product.productArray[0].price -
                product.productArray[0].basePrice;
              let quantity = product.productQuantity + 1;
              let quantityAmount = amount * quantity;
              cartProduct[i].productAmount =
                quantity * product.productBasePrice + quantityAmount;
              cartProduct[i].productQuantity = quantity;
            } else {
              let quantity = product.productQuantity + 1;
              cartProduct[i].productAmount =
                quantity * product.productBasePrice;
              cartProduct[i].productQuantity = quantity;
            }
          } else if (type === "MINUS") {
            if (product.productQuantity > 1) {
              if (product.productArray.length > 0) {
                let amount =
                  product.productArray[0].price -
                  product.productArray[0].basePrice;
                let quantity = product.productQuantity - 1;
                let quantityAmount = amount * quantity;
                cartProduct[i].productAmount =
                  quantity * product.productBasePrice + quantityAmount;
                cartProduct[i].productQuantity = quantity;
              } else {
                let quantity = product.productQuantity - 1;
                cartProduct[i].productAmount =
                  quantity * product.productBasePrice;
                cartProduct[i].productQuantity = quantity;
              }
            } else {
              openNotificationWithIcon({
                type: "warning",
                msg: "Not Possible",
                description: "Quantity Should be One or more than one!",
              });
            }
          }
          setCartProduct([...cartProduct]);
          getCountAmount(cartProduct);
          break;
        }
      }
    } else {
      openNotificationWithIcon({
        type: "warning",
        msg: "Not Possible",
        description: "Quantity Should be One or more than one!",
      });
    }
  };

  const deleteAllCartItems = () => {
    for (let i = 0; i < cartProduct.length; i++) {
      deleteFromCart(cartProduct[i]._id, "FINAL");
    }
  };

  return (
    <>
      <header className="ex-header">
        <div className="container">
          <div className="row">
            <div className="col-xl-12" style={{ textAlign: "center" }}>
              <h1>
                Hey {user.user.name ? user.user.name : "Guest"}, Welcome to your
                cart
              </h1>
            </div>
            {/* <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <Link to={`/categories`}>
                                <Button type="primary" icon={<ArrowLeftOutlined style={{ fontSize: '22px'}} />} size="large">
                                    Back to categories
                                </Button>
                            </Link>
                        </div> */}
            <ButtonBackCat />
            <div className="col-xl-6" style={{ textAlign: "center" }}>
              <h1>Total Amount: &#8377; {amount}.00</h1>
              <div>
                {amount === 0 ? (
                  ""
                ) : amount < 100 ? (
                  <p style={{ color: "green" }}>
                    <b>&#8377;50 Delivery Charges</b>
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className="col-xl-6"
              style={{ textAlign: "center", width: "100%" }}
            >
              <Button
                type="danger"
                size="large"
                style={{ width: "150px" }}
                icon={
                  <DeleteOutlined
                    style={{
                      fontSize: "27px",
                      color: "red",
                      position: "absolute",
                      top: "2px",
                      left: "10px",
                    }}
                  />
                }
                onClick={(e) => deleteAllCartItems(e)}
              >
                Delete All
              </Button>
            </div>
            {cartProduct.length > 1 ? (
              <div className="col-xl-12" style={{ textAlign: "center" }}>
                <Button type="primary" onClick={(e) => checkOutAll(e)}>
                  Check Out All
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </header>
      <div className="ex-cards-1 pt-3 pb-3">
        <div className="container">
          <div className="row">
            {cartProduct.length > 0 ? (
              cartProduct.map((product, index) => {
                return (
                  <div
                    className="col-lg-4"
                    style={{ marginBottom: " 20px" }}
                    key={index}
                  >
                    <div className="product-card">
                      {/* <div className="logo-cart">
                                        <img src="./images/logo.jpg" alt="Smart Uniforms" />
                                        <i className='bx bx-shopping-bag'></i>
                                    </div> */}
                      <div className="main-images">
                        <img
                          id="blue"
                          className="blue active"
                          src={product.productImage}
                          alt="Smart Uniforms"
                        />
                      </div>
                      <div className="product_details">
                        <span className="product_name">
                          {product.productName}
                        </span>
                        <p>{product.productDescription}</p>
                        {/* <div className="stars">
                                            <Rate disabled defaultValue={product.productReview} />
                                        </div> */}
                        <span>
                          {product.productArray.length > 0 ? (
                            <>
                              Selected Size :{" "}
                              <b>{product.productArray[0].size}</b> <br />
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                        <span>
                          {product.productColor ? (
                            <>
                              Selected Colour :{" "}
                              <b style={{ color: product.productColor }}>
                                {product.productColor}
                              </b>
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      <div>
                        <Button
                          type="primary"
                          shape="circle"
                          onClick={(e) => changeQuantity("MINUS", product)}
                        >
                          {" "}
                          <b className="prodQuanMinus">-</b>{" "}
                        </Button>
                        <span style={{ margin: "12px" }}>
                          {product.productQuantity}
                        </span>
                        <Button
                          type="primary"
                          shape="circle"
                          onClick={(e) => changeQuantity("PLUS", product)}
                        >
                          {" "}
                          <b className="prodQuan">+</b>{" "}
                        </Button>
                      </div>
                      <div className="color-price">
                        <div className="color-option">
                          {/* <span className="color">Quantity:</span> */}
                          <div className="circles"></div>
                        </div>
                        <div className="price">
                          <span className="price_num">
                            &#8377; {product.productAmount}.00
                          </span>
                          <span className="price_letter">
                            Inclusive of all Taxes
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="button">
                            <div className="button-layer"></div>
                            <button onClick={(e) => proceedToCheckOut(product)}>
                              Checkout
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="button">
                            <div className="button-layer-del"></div>
                            <button
                              onClick={(e) => deleteFromCart(product._id)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div style={textStyle}> Sorry No Products in your cart </div>
            )}
          </div>
        </div>
        <div></div>
      </div>
      <>
        <Modal
          title="Please Select Address"
          visible={addressModal}
          width="100%"
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {userAddress.length > 0 ? (
            <div>
              <Table
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                rowKey={(record) => record._id}
                columns={addressHeader}
                locale={{
                  emptyText:
                    "Please visit My Home Address section to add your address",
                }}
                dataSource={userAddress}
              />
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Link to={{ pathname: "/user", state: { key: "2" } }}>
                Sorry you didn't add your address, Click here to add address.
              </Link>
            </div>
          )}
          <>
            {userAddress.length > 0 && add === false ? (
              <p>
                <p style={{ textAlign: "center", color: "red" }}>{addErr}</p>
              </p>
            ) : (
              ""
            )}
          </>
        </Modal>
      </>
    </>
  );
}

export default Cart;
