import { withRouter, useHistory } from "react-router-dom";
import { Card } from "antd";

const cardData = [{
    name:'Boy',
    value:'Boy',
    image:'../images/products/dmcs/boy.jpg'
}, {
    name:'Girl',
    value:'Girl',
    image:'../images/products/dmcs/girl.png'
}]

function DMCS(props) {
    let history = useHistory();

    const setGenderData = (value) => {
        history.push({
            pathname: '/dmcs/'+ value + '/' + props.location.state.value,
            state: props.location.state
        });
    }

    return (
        <>
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <h1>{props.location.state.cardDescription}</h1>
                        </div>
                        {/* <ButtonBackCat /> */}
                    </div>
                </div>
            </header>
            <div className="container">
                <div className="row" style={{margin:'30px'}}>
                    {cardData.map((data, index) => {
                        return <div onClick={(e) => setGenderData(data.value)} style={{margin:'0 auto'}} key={index}>
                            <Card
                        hoverable
                        style={{ width: '100%' }}
                        cover={<img alt="Smart Uniforms" style={{ margin: '0 auto', height: '400px', width: '250px', marginTop: '10px' }} src={data.image} />}
                    >
                        <p style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', color: 'darkblue' }}>{data.name}</p>
                    </Card>
                        </div> 
                    }) }
                </div>
            </div>
        </>
    )
}

export default withRouter(DMCS);