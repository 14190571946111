import { Carousel, Card } from 'antd';
import { categories } from './Constants/School'
import { Link, withRouter } from 'react-router-dom';
import Img0 from './Images/intro.jpg';
import Img1 from './Images/intro1.jpg';
import Img2 from './Images/2.png';
import Img3 from './Images/intro2.jpg';
import Img4 from './Images/4.jpg';
import Img5 from './Images/5.jpg';
import Categories from './Categories/Category';

const { Meta } = Card;

function Landing() {
    return (
        <div className="container">
            <div style={{marginTop: ' 120px' }}>
                <div className="slider-container">
                    <div className="swiper-container card-slider">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <img src={Img1} className="img-fluid" alt='smart uniforms' />
                            </div>
                            <div className="swiper-slide">
                                <img src={Img2} className="img-fluid" alt='smart uniforms' />
                            </div>
                            <div className="swiper-slide">
                                <img src={Img3} className="img-fluid" alt='smart uniforms' />
                            </div>
                            <div className="swiper-slide">
                                <img src={Img4} className="img-fluid" alt='smart uniforms' />
                            </div>
                            <div className="swiper-slide">
                                <img src={Img5} className="img-fluid" alt='smart uniforms' />
                            </div>
                        </div>
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>

                    </div>
                </div>
            </div>
            <Categories />
        </div>
    )
}

export default withRouter(Landing);