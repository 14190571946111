import { Select, Button, Image, Card } from 'antd';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons'
import ButtonBackCat from '../Constants/ButtonCat';
// const Data = []
const { Option } = Select;

function Measurement(props) {
    const [Data, setData] = useState([])
    const [dataValue, setDataValue] = useState('')

    useEffect(() => {
        // setDataValue(props.match.params.id)
        selectSelectedSchool(props)
        // onChange(props.match.params.id)
    }, [])

const selectSelectedSchool = (data) => {
    if(data.location.state) {
        if(data.location.state.data.data) {
             if (data.location.state.data.data.school === 'DMCS') {
                var data = require(`../Measurement/DMCSMEASURE.json`)
                setData([...data])
                onChange(data, props.match.params.id)
            } 
        } else {
            if(data.location.state.data.school === 'JSPS') {
                var data = require(`../Measurement/JSPSMEASURE.json`)
                setData([...data])
                onChange(data, props.match.params.id)
            } else {
                var data = require(`../Measurement/NPSMEASURE.json`)
                setData([...data])
                onChange(data, props.match.params.id)
            }
        }
    } else {
        var data = require(`../Measurement/NPSMEASURE.json`)
            setData([...data])
    }
    
    // console.log(data)
}
    const onChange = (data, value) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].value === value) {
                // window.location.href = data[i].link
                window.open(
                    data[i].link,
                    '_blank' // <- This is what makes it open in a new window.
                  );
                break
            }
        }
    }

    const onSearch = (val) => {
        // console.log('search:', val);
    }

    return (
        <>
        <header id="header" className="header">
            <div className="container">
            <div className="row">
                        <div className="col-xl-12" style={{ textAlign: 'center' }}>
                            <h1>Measurement</h1>
                        </div>
                        {props.location.state  ? <div className="col-xl-12" style={{ textAlign: 'center' }}> 
                        <Link to={{pathname:'/products', state: props.location.state.data}}>
                            <Button type="primary" style={{width: '207px'}} icon={<ArrowLeftOutlined style={{ fontSize: '27px', color :'red' ,position:'absolute', top:'2px', left:'7px'}} />} size="large">
                                <b style={{float:'right', fontSize: '17px'}}>Back to Products</b>
                            </Button>
                        </Link> </div>: <ButtonBackCat />}
                    </div>
                    <br />
                </div>
            {/* </div/> */}
            </header>
                <div className="container">
                <div className="row" style={{ margin: '30px' }}>
                    {/* <div className="col-lg-12">
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a Product"
                            optionFilterProp="children"
                            size="large"
                            defaultValue={props.match.params.id}
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {Data.map((name, value) => {
                                return <Option value={name.value} key={value}>{name.title}</Option>
                            })}
                        </Select>
                    </div> */}
                    {Data.map((name, index) =>{
                         return <div className="col-lg-4 col-md-4 col-sm-4" style={{ marginTop: ' 20px' }} key={index}>
                         <a href={name.link} target="_blank">
                             <Card
                                 hoverable
                                 style={{ width: '100%' }}
                                 cover={<img alt="Smart Uniforms" style={{ margin: '0 auto', height: '240px', width: '250px', marginTop: '10px' }} src={name.pic} />}
                             >
                                 <p style={{textAlign:'center', fontSize:'20px', fontWeight:'bold', color:'darkblue'}}>{name.title}</p>
                             </Card>
                         </a>
                     </div>
                    })}
                    </div>
            </div>
            {/* <div style={{margin:"20px auto"}}>
            <div id="features" className="cards-1">
                <div className="container"> */}
                    {/* <div className="row">
                        <div className="col-lg-12" style={{textAlign: "left"}}>
                            <h2 className="h2-heading">How to Measure for the {selectedData.title}</h2>
                            <p style={{color: '#ffffff',opacity: 0.8, fontSize:'16px', fontWeight:'bold'}}>Instructions before taking the measurement</p>
                            {selectedData.description.map((data, index) => {
                                return <p style={{color: '#ffffff',opacity: 0.8}} key={index}>{index + 1}{'.'}{data}</p>
                            })}
                        </div>
                    </div> */}
                    {/* <div className="row">
                        <div className="col-lg-12">
                        {selectedData.images.map((data, index) => {
                            return <div className="card" key={index}>
                            {data.src ? <div className="card-icon-wrapper">
                                <div className="card-icon">
                                    <Image src={data.src} alt="Smart Uniforms" style={{height:'230px', width:'300px'}} />
                                </div>
                            </div> : ''}
                            <div className="card-body">
                                <h4 className="card-title" style={{textAlign:'center'}}>{data.title.split('@')[0]}</h4>
                                {data.title.split('@')[1] ? <h5 style={{textAlign:'center'}} className="card-title">{data.title.split('@')[1]}</h5> : ''}
                                <p style={{textAlign:'justify'}}>{data.text}</p>
                            </div>
                        </div>
                        })}
                        </div>
                    </div> */}
                {/* </div> */}
            {/* </div>
            </div> */}
       </>
    )
}

export default Measurement