import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { Provider } from "react-redux";
import store from "./store";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import PrivateRoute from "./privateroute/PrivateRoute";
import Home from "./components/Home";
import Login from "./components/Login";
import Landing from "./components/Landing";
// import NotFound from './components/NotFound';
import Nav from "./components/Nav";
import Register from "./components/Register";
import VerifyUser from "./components/VerifyYourself";
import Footer from "./components/Footer";
import Measurement from "./components/Measurement/Measurement";
import Products from "./components/Products/Products";
import About from "./components/About";
import Cart from "./components/Cart";
import User from "./components/User/User";
import Admin from "./components/Admin/Admin";
import Washing from "./components/Measurement/WashingCaring";
import Payment from "./components/Payment";
import PrivacyPolicy from "./components/WebsiteInstructions/PrivacyPolicy";
import TermsAndConditions from "./components/WebsiteInstructions/TermsAndConditions";
import Categories from "./components/Categories/Category";
import SchoolsList from "./components/Categories/Schools";
import SelectedCategory from "./components/Categories/SelectedCategory";
import BulBul from "./components/Categories/BulBul";
import DMCS from "./components/Categories/DMCS";
import DMCSClasses from "./components/Categories/DMCSClasses";
import DeliveryInstructions from "./components/WebsiteInstructions/DeliveryInstructions";
import ExchangePolicy from "./components/WebsiteInstructions/ExchangePolicy";
import Invoice from "./components/Admin/Invoice";
import ForgotPassword from "./components/ForgotPassword";

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "./login";
  }
}

function App() {
  return (
    <Provider store={store}>
      <Nav />
      <Router>
        <Route exact path="/" component={Landing} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/verifyourself/:id" component={VerifyUser} />
        <Route exact path="/resetpassword/:id" component={ForgotPassword} />
        <Route exact path="/measureclothes" component={Measurement} />
        <Route exact path="/measureclothes/:id" component={Measurement} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/about" component={About} />
        <Route exact path="/washingandcaring" component={Washing} />
        <Route exact path="/privacy" component={PrivacyPolicy} />
        <Route
          exact
          path="/termsandconditions"
          component={TermsAndConditions}
        />
        <Route exact path="/deliveryinfo" component={DeliveryInstructions} />
        <Route exact path="/exchangepolicy" component={ExchangePolicy} />
        <Route exact path="/categories" component={Categories} />
        <Route exact path="/schoolslist/:id" component={SchoolsList} />
        <Route exact path="/selectedcat/:id" component={SelectedCategory} />
        <Route exact path="/cubslist/:id" component={BulBul} />
        <Route exact path="/dmcs/:id" component={DMCS} />
        <Route exact path="/dmcs/:id/:id" component={DMCSClasses} />
        <Switch>
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/cart" component={Cart} />
          <PrivateRoute exact path="/user" component={User} />
          <PrivateRoute exact path="/admin" component={Admin} />
          <PrivateRoute exact path="/payment" component={Payment} />
          <PrivateRoute exact path="/invoice/:id" component={Invoice} />
          {/* <Route path="/404" component={NotFound} /> */}
          {/* <Route component={NotFound} /> */}
        </Switch>
        {/* <Route component={NotFound} /> */}
      </Router>
      <Footer />
    </Provider>
  );
}

export default App;
