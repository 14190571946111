export const schools = [
    {
        school:'NPS',
        value:'NPS',
        cardName:'All NPS',
        cardDescription:'',
        schoolLogo:'../images/schoolslist/nps&nhvps.jpg'
    },{
        school:'NPS',
        value:'NPS',
        cardName:'All NPS',
        cardDescription:'National Public School',
        schoolLogo:'../images/schoolslist/nps&nhvps.jpg'
    },{
        school:'JSPS',
        value:'JSPS',
        cardName:'JSPS',
        cardDescription:'Jnana Sweekar Public School',
        schoolLogo:'../images/schoolslist/jsps.JPG'

    },{ 
        school:'DMCS',
        value:'DMCS_HORAMAVU',
        cardName:'DMCS',
        cardDescription:'Devamatha Central School, Horamavu',
        schoolLogo:'../images/schoolslist/dmcs_h.JPG'
    },{ 
        school:'DMCS',
        value:'DMCS_VIDYARANYAPURA',
        cardName:'DMCS',
        cardDescription:'Devamatha Central School, Vidyaranyapura',
        schoolLogo:'../images/schoolslist/dmcs_v.JPG'
    },{ 
        school:'SLN',
        value:'SLN_SATHANUR',
        cardName:'SLN Convent',
        cardDescription:'SLN Convent, Sathanur',
        schoolLogo:'../images/schoolslist/sln_sathanur.jpg'
    },{ 
        school:'SLN',
        value:'SLN_MANDYA',
        cardName:'SLN Convent',
        cardDescription:'SLN Convent, Mandya',
        schoolLogo:'../images/schoolslist/sln_mandya.jpg'
    },{ 
        school:'R K',
        value:'RK',
        cardName:'R.K Educational Institute',
        cardDescription:'R.K Educational Institute, Maddur',
        schoolLogo:'../images/schoolslist/rk.jpg'
    },{
        school:'NPS',
        value:'NPS_K',
        cardName:'NPS',
        cardDescription:'National Public School, Kengeri',
        schoolLogo:'../images/schoolslist/nps&nhvps.jpg'
    },{
        school:'NPS',
        value:'NPS_C',
        cardName:'NPS',
        cardDescription:'National Public School, Chikkabanavara',
        schoolLogo:'../images/schoolslist/nps&nhvps.jpg'
    },{
        school:'NPS',
        value:'NPS_G',
        cardName:'NPS',
        cardDescription:'National Public School T J Campus, Gottigere',
        schoolLogo:'../images/schoolslist/nps&nhvps.jpg'
    },{
        school:'BNPS',
        value:'BNPS_V',
        cardName:'NPS',
        cardDescription:'Brooklyn National Public School, Vijayanagar',
        schoolLogo:'../images/schoolslist/nps&nhvps.jpg'
    },{
        school:'BNPS',
        value:'BNPS_J',
        cardName:'NPS',
        cardDescription:'Brooklyn National Public School, Jayanagar',
        schoolLogo:'../images/schoolslist/nps&nhvps.jpg'
    },{
        school:'BNPS',
        value:'BNPS_K',
        cardName:'NPS',
        cardDescription:'Brooklyn National Public School, Kanakapura',
        schoolLogo:'../images/schoolslist/nps&nhvps.jpg'
    },{
        school:'RIS',
        value:'RIS',
        cardName:'RIS',
        cardDescription:'Rainbow International School, Abbigere',
        schoolLogo:'../images/schoolslist/ris.jpg'
    },{
        school:'Jnanamudra Vidyaniketana',
        value:'JNANA_MUDRA_VIDYANIKETHAN',
        cardName:'Jnanamudra Vidyaniketana',
        cardDescription:'Jnanamudra Vidyaniketana, Kanakapura',
        schoolLogo:'../images/schoolslist/jmv.jpg'
    }
]

export const gender = [
    {
        name:'Boy',
        value:'boy'
    },{
        name:'Girl',
        value:'girl'
    }
]

export const studentClass = [
    {
        name:'Pre KG',
        value:'LKG',
    },{
        name:'LKG',
        value:'LKG'
    },{
        name:'UKG',
        value:'LKG'
    },{
        name:'1st STD',
        value:'1-4'
    },{
        name:'2nd STD',
        value:'1-4'
    },{
        name:'3rd STD',
        value:'1-4'
    },{
        name:'4th STD',
        value:'1-4'
    },{
        name:'5th STD',
        value:'5-10'
    },{
        name:'6th STD',
        value:'5-10'
    },{
        name:'7th STD',
        value:'5-10'
    },{
        name:'8th STD',
        value:'5-10'
    },{
        name:'9th STD',
        value:'5-10'
    },{
        name:'10th STD',
        value:'5-10'
    },{
        name:'11th STD',
        value:'PUC'
    },{
        name:'12th STD',
        value:'PUC'
    },
]

export const studentClassData = [
    {
        name:'Pre KG',
        value:'Pre KG',
    },{
        name:'LKG',
        value:'LKG'
    },{
        name:'UKG',
        value:'UKG'
    },{
        name:'1st STD',
        value:'1'
    },{
        name:'2nd STD',
        value:'2'
    },{
        name:'3rd STD',
        value:'3'
    },{
        name:'4th STD',
        value:'4'
    },{
        name:'5th STD',
        value:'5'
    },{
        name:'6th STD',
        value:'6'
    },{
        name:'7th STD',
        value:'7'
    },{
        name:'8th STD',
        value:'8'
    },{
        name:'9th STD',
        value:'9'
    },{
        name:'10th STD',
        value:'10'
    },{
        name:'11th STD',
        value:'11'
    },{
        name:'12th STD',
        value:'12'
    },
]

export const categories = [
    {
        name:'School Uniforms',
        value:'SCHOOL_UNIFORMS',
        image:'./images/categories/schooluniforms.jpg'
    },{
        name:'Cubs & Bulbuls',
        sName:'Scouts & Guides Uniforms',
        value:'CUBS_BULBULS',
        image:'./images/categories/cubs&bulbuls.jpg'
    },{
        name:'Lab Coats',
        value:'LAB_COATS',
        image:'./images/categories/labcoats.JPG'
    },{
        name:'Graduation Gowns',
        value:'GRADUATION_GOWNS',
        image:'./images/categories/graduationgowns.jpg'
    },{
        name:'Blazers',
        value:'BLAZERS',
        image:'./images/categories/blazers.jpeg'
    },{
        name:'Hospital Uniforms',
        value:'HOSPITAL_UNIFORMS',
        image:'./images/categories/hospitaluniforms.jpg'
    },{
        name:'Corporate Uniforms',
        value:'CORPORATE_UNIFORMS',
        image:'./images/categories/corporateuniforms.jpg'
    },{
        name:'Hotel Uniforms',
        value:'HOTEL_UNIFORMS',
        image:'./images/categories/hoteluniforms.jpg'
    },{
        name:'Sports Uniforms',
        value:'SPORTS_UNIFORMS',
        image:'./images/categories/sportsuniforms.jpg'
    },{
        name:'Security Uniforms',
        value:'SECURITY_UNIFORMS',
        image:'./images/categories/securityuniforms.jpg'
    },
]