import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import swal from 'sweetalert';
import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING, SET_CART_DATA } from "./types";

const headers = {
  headers: { Token: localStorage.getItem("jwtToken") },
};

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post("v1/users/register", userData)
    .then(res => {
      // openNotificationWithIcon({ type: 'success', msg: 'Registration Success', description: 'Please visit your mail to confirm your registration.!' })
      swal("Success", "You're now registered with us, Please check your email to confirm your account.", "success");
      history.push("/login")
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Login - get user token
export const loginUser = userData => dispatch => {
  axios
    .post("v1/users/login", userData)
    .then(res => {
      // Save to localStorage

      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const verifyYourself = (id) => dispatch => {
  axios.get(`https://smartuniforms.in/v1/users/verify/${id}`)
  .then(res => console.log(res))
  .catch(err => console.log(err))
}

export const Me = () => dispatch => {
  axios.get("v1/users/me", headers)
  .then(res => console.log(res))
  .catch(err => console.log(err))
}

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const cartProducts = data => {
  return {
    type: SET_CART_DATA,
    payload: data
  }
}

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};