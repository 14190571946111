import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Rate,
  Select,
  Modal,
  Button,
  Tooltip,
  Image,
  Popover,
  Avatar,
  Badge,
} from "antd";
import "./Products.css";
import axios from "axios";
import { openNotificationWithIcon } from "../Constants/Notifications";
import { schools, gender, studentClass } from "../Constants/School";
import { Link, useHistory } from "react-router-dom";
import {
  ArrowLeftOutlined,
  ShoppingCartOutlined,
  WhatsAppOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import swal from "sweetalert";

import ButtonBackCat from "../Constants/ButtonCat";
// const productsData = require('./Products.json')

const { Option } = Select;

const cart = [];

var str1 = "";
var str2 = "";

function Products(props) {
  const user = useSelector((state) => state.auth);
  const [isAuthenticated, setAuthenticated] = useState(false);
  // const [products, setProducts] = useState([])
  // const [amount, setAmount] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedSize, setSelectedSize] = useState([]);
  const [school, setSchool] = useState(user.user.school);
  const [modal, setModal] = useState(false);
  const [cartModal, setCartModal] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [guestCartItems, setGuestCartItems] = useState([]);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [msg, setMsg] = useState("");
  const [cartQuantity, setCartQuantity] = useState("");
  const [lernersTrackSet, setLernersTrackSet] = useState([]);
  let history = useHistory();

  useEffect(() => {
    console.log(user)
    if (user.isAuthenticated) {
      setAuthenticated(true);
      // displayProducts(school)
      // setSchool(user.user.school)
    } else {
      setAuthenticated(false);
      // getSchoolNameFromGuest()
    }
    getProducts();
    getUserCartData();
  }, []);

  const getProducts = () => {
    var school = props.location.state.data
      ? props.location.state.data.school
      : props.location.state.value;
    if (
      props.location.state.value === "DMCS_HORAMAVU" ||
      props.location.state.value === "DMCS_VIDYARANYAPURA"
    ) {
      // <Link to = {{pathName:`/dmcs/${props.location.state.value}`, state:props.location.state}} />
      history.push({
        pathname: "/dmcs/" + props.location.state.value,
        // search: '?query=abc',
        state: props.location.state,
      });
    } else if (school === "DMCS") {
      let url = `../Products/DMCS/${props.location.state.gender}/${props.location.state.selectedClass.value}.json`;
      // console.log(url)
      var data = require(`../Products/DMCS/${props.location.state.gender}/${props.location.state.selectedClass.value}.json`);
      // console.log(data[0].products)
      setMsg("");
      setSelectedProduct(data[0].products);
    } else if (
      props.location.state.value === "NPS" ||
      props.location.state.value === "JSPS" ||
      props.location.state.value === "NPS_K" ||
      props.location.state.value === "NPS_C" ||
      props.location.state.value === "NPS_G" ||
      props.location.state.value === "BNPS_V" ||
      props.location.state.value === "BNPS_J" ||
      props.location.state.value === "BNPS_K"
    ) {

      // if(user.user.userRole === 'ADMIN') {
        var data = require(`../Products/${props.location.state.value}.json`);
        // console.log(data[0].products)
        setSelectedProduct(data[0].products);
        setMsg("");
      // } else {
      //   setMsg("Products will be updating soon.");
      // }
      // var data = require(`../Products/${props.location.state.value}.json`);
      // // console.log(data[0].products)
      // setSelectedProduct(data[0].products);
      // setMsg("");
    } else if (
      props.location.state.value === "SLN_MANDYA" ||
      props.location.state.value === "SLN_SATHANUR"
    ) {
      var data = require(`../Products/SLN_SATHANUR.json`);
      // console.log(data[0].products)
      setSelectedProduct(data[0].products);
      setMsg("");
    } else if (props.location.state.value === "sBoys") {
      var data = require(`../Products/Scouts/boy.json`);
      // console.log(data[0].products)
      setSelectedProduct(data[0].products);
      setMsg("");
    } else if (props.location.state.value === "sGirls") {
      var data = require(`../Products/Scouts/girl.json`);
      // console.log(data[0].products)
      setSelectedProduct(data[0].products);
      setMsg("");
    } else {
      setMsg("Products will be updating soon.");
    }
  };

  // const getSchoolNameFromGuest = () => {
  //     var tempSchool = localStorage.getItem('school')
  //     if (tempSchool) {
  //         setModal(false)
  //         displayProducts(tempSchool)
  //         setSchool(tempSchool)
  //     } else {
  //         setModal(true)
  //     }
  // }

  // const onSchoolChange = (value) => {
  //     setSchool(value)
  //     displayProducts(value)
  // }

  const onSchoolSearch = (value) => {
    // console.log('search:', value);
  };

  const onGenderChange = (value) => {
    setSelectedGender(value);
  };

  const onGenderSearch = (value) => {
    // console.log('search:', value);
  };

  const onClassChange = (value) => {
    setSelectedClass(value);
  };

  const onClassSearch = (value) => {
    // console.log('search:', value);
  };

  // const displayProducts = (value) => {
  //     for (let i = 0; i < productsData.length; i++) {
  //         if (productsData[i].school === value) {
  //             setSelectedProduct(productsData[i].products)
  //             break
  //         } else if (value === "NHVPS") {
  //             setSelectedProduct(productsData[0].products)
  //             break
  //         }
  //     }
  // }

  // const handleOk = () => {
  //     localStorage.setItem('school', school)
  //     displayProducts(school)
  //     setModal(false);
  // };

  // const handleCancel = () => {
  //     setModal(false);
  // };

  const onColorChange = (value) => {
    var id = value.split("@")[1];
    var color = value.split("@")[0];
    for (var i = 0; i < selectedProduct.length; i++) {
      if (selectedProduct[i].productId === id) {
        if (selectedProduct[i].productColors) {
          for (var j = 0; j < selectedProduct[i].productColors.length; j++) {
            if (selectedProduct[i].productColors[j].color === color) {
              selectedProduct[i].productColor =
                selectedProduct[i].productColors[j].color;
              selectedProduct[i].productImage =
                selectedProduct[i].productColors[j].image;
              break;
            }
          }
        }
      } else {
        // console.log('id not matching')
      }
    }
    setSelectedProduct([...selectedProduct]);
  };

  const onChange = (value) => {
    // console.log(value)
    var id = value.split("@")[1];
    var getDimension = value.split("@")[0];
    for (var i = 0; i < selectedProduct.length; i++) {
      if (selectedProduct[i].productId === id) {
        if (selectedProduct[i].productArray) {
          for (var j = 0; j < selectedProduct[i].productArray.length; j++) {
            if (selectedProduct[i].productArray[j].size === getDimension) {
              if (selectedProduct[i].productId === "NH-1114-APRN") {
                setSelectedSize(selectedProduct[i].productArray[j]);
                selectedProduct[i].productAmount =
                  selectedProduct[i].productArray[j].price *
                  selectedProduct[i].productQuantity;
                selectedProduct[i].productColor =
                  selectedProduct[i].productArray[j].color;
                selectedProduct[i].productImage =
                  selectedProduct[i].productArray[j].image;
                break;
              } else {
                setSelectedSize(selectedProduct[i].productArray[j]);
                selectedProduct[i].productAmount =
                  selectedProduct[i].productArray[j].price *
                  selectedProduct[i].productQuantity;
                break;
              }
            }
          }
        }
      } else {
        // console.log('id not matching')
      }
    }
    setSelectedProduct([...selectedProduct]);
  };

  const handleCartOk = () => {
    setCartModal(false);
  };

  const handleCartOpen = () => {
    var cartItemsForGuest = JSON.parse(localStorage.getItem("cart"));
    setGuestCartItems(cartItemsForGuest);
    setCartModal(true);
    setGuestCartItems([...guestCartItems]);
    // console.log(cartItemsForGuest)
  };

  const handleCartCancel = () => {
    setCartModal(false);
  };

  const onhandlechange = (value) => {
    str1 = value.split("@")[0];
  };

  const onhandlechange1 = (value) => {
    str2 = value.split("@")[0];
  };

  const checkingThisProductInDiffrentWay = (data) => {
    // console.log(data)
    var minObj = {
      size: str1 + " Tshirt," + str2 + " Track Nicker",
      sizeDescription: "in Inches",
      price: 990,
      basePrice: 990,
      productId: "JS-1029-BLTRKST",
    };

    var obj = {
      userId: user.user.id,
      productName: data.productName,
      productId: data.productId,
      productTitle: data.productTitle,
      productReview: data.productReview,
      productQuantity: data.productQuantity,
      productBasePrice: data.productBasePrice,
      productDescription: data.productDescription,
      productAmount: data.productAmount,
      productImage: data.productImage,
      productArray: [minObj],
      productColor: data.productColor,
      productSchool: props.location.state.value,
    };

    console.log(obj);

    if (data.productId === minObj.productId) {
      if (str1.length > 0 && str2.length > 0) {
        axios
          .post("v1/carts/addtocart", obj)
          .then((res) => {
            str1 = "";
            str2 = "";
            openNotificationWithIcon({
              type: "success",
              msg: "Item Added",
              description:
                "Item successfully added to your cart, You can visit cart now.!",
            });
          })
          .catch((err) => {
            // console.log(err)
          });
      } else {
        swal("Warning", "Please select the size of the product.", "warning");
      }
    } else {
      console.log("diffrent size");
    }
  };

  const getUserCartData = () => {
    axios
      .get(`v1/carts/getusercart?userId=${user.user.id}`)
      .then((res) => {
        setCartQuantity(res.data.length);
        // getCountAmount(res.data)
      })
      .catch((err) => console.log(err));
  };

  const addingToCart = (data) => {
    if (!user.isAuthenticated) {
      history.push({
        pathname: "/login",
        state: {
          text: "Hey Guest, Please login to add your items to cart",
          url: props.location.state.value,
        },
      });
    }

    var obj = {
      userId: user.user.id,
      productName: data.productName,
      productId: data.productId,
      productTitle: data.productTitle,
      productReview: data.productReview,
      productQuantity: data.productQuantity,
      productBasePrice: data.productBasePrice,
      productDescription: data.productDescription,
      productAmount: data.productAmount,
      productImage: data.productImage,
      productArray: selectedSize,
      productColor: data.productColor,
      productSchool: props.location.state.value,
    };

    if (data.productId === "JS-1029-BLTRKST") {
      checkingThisProductInDiffrentWay(obj);

      return;
    }

    if (user.isAuthenticated && obj.productId) {
      if (
        user.isAuthenticated &&
        obj.productId &&
        obj.productAmount &&
        data.productArray === undefined
      ) {
        setCartQuantity(cartQuantity + 1);
        axios
          .post("v1/carts/addtocart", obj)
          .then((res) =>
            openNotificationWithIcon({
              type: "success",
              msg: "Item Added",
              description:
                "Item successfully added to your cart, You can visit cart now.!",
            })
          )
          .catch((err) => {
            // console.log(err)
          });
      } else if (
        user.isAuthenticated &&
        obj.productId === selectedSize.productId &&
        obj.productArray.size
      ) {
        if (data.productColors) {
          if (obj.productColor) {
            axios
              .post("v1/carts/addtocart", obj)
              .then((res) => {
                setCartQuantity(cartQuantity + 1);
                openNotificationWithIcon({
                  type: "success",
                  msg: "Item Added",
                  description:
                    "Item successfully added to your cart, You can visit cart now.!",
                });
              })
              .catch((err) => {
                // console.log(err)
              });
          } else {
            swal(
              "Warning",
              "Please select the color of the product.",
              "warning"
            );
          }
        } else {
          setCartQuantity(cartQuantity + 1);
          axios
            .post("v1/carts/addtocart", obj)
            .then((res) =>
              openNotificationWithIcon({
                type: "success",
                msg: "Item Added",
                description:
                  "Item successfully added to your cart, You can visit cart now.!",
              })
            )
            .catch((err) => {
              // console.log(err)
            });
        }
      } else {
        swal("Warning", "Please select the size of the product.", "warning");
      }
    } else {
      swal(
        "Warning",
        "Product data updating, please try again after some time",
        "warning"
      );
    }
  };

  const onSearch = (val) => {
    // console.log('search:', val);
  };

  const changeQuantity = (type, product) => {
    // console.log(type, product)
    if (product.productQuantity > 0) {
      for (let i = 0; i < selectedProduct.length; i++) {
        if (product.productId === selectedProduct[i].productId) {
          if (type === "PLUS") {
            // console.log(selectedSize)
            if (selectedSize.basePrice) {
              let amount = selectedSize.price - selectedSize.basePrice;
              let quantity = product.productQuantity + 1;
              let quantityAmount = amount * quantity;
              selectedProduct[i].productAmount =
                quantity * product.productBasePrice + quantityAmount;
              selectedProduct[i].productQuantity = quantity;
            } else {
              let quantity = product.productQuantity + 1;
              selectedProduct[i].productAmount =
                quantity * product.productBasePrice;
              selectedProduct[i].productQuantity = quantity;
            }
          } else if (type === "MINUS") {
            if (product.productQuantity > 1) {
              if (selectedSize.basePrice) {
                let amount = selectedSize.price - selectedSize.basePrice;
                let quantity = product.productQuantity - 1;
                let quantityAmount = amount * quantity;
                selectedProduct[i].productAmount =
                  quantity * product.productBasePrice + quantityAmount;
                selectedProduct[i].productQuantity = quantity;
              } else {
                let quantity = product.productQuantity - 1;
                selectedProduct[i].productAmount =
                  quantity * product.productBasePrice;
                selectedProduct[i].productQuantity = quantity;
              }
            } else {
              openNotificationWithIcon({
                type: "warning",
                msg: "Not Possible",
                description: "Quantity Should be One or more than one!",
              });
            }
          }
          setSelectedProduct([...selectedProduct]);
          break;
        }
      }
    } else {
      openNotificationWithIcon({
        type: "warning",
        msg: "Not Possible",
        description: "Quantity Should be One or more than one!",
      });
    }
  };

  return (
    <>
      <header className="ex-header">
        <div className="container">
          <div className="row">
            {/* {school === "DMCS" ? <>
                            <div className="col-lg-6">
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Select a Gender"
                                    optionFilterProp="children"
                                    size="large"
                                    // defaultValue={products[0].school}
                                    onChange={onGenderChange}
                                    onSearch={onGenderSearch}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {gender.map((name, value) => {
                                        return <Option value={name.value} key={value}>{name.name}</Option>
                                    })}
                                </Select>
                            </div>
                            <div className="col-lg-6">
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Select a Class"
                                    optionFilterProp="children"
                                    size="large"
                                    // defaultValue={products[0].school}
                                    onChange={onClassChange}
                                    onSearch={onClassSearch}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {studentClass.map((name, value) => {
                                        return <Option value={name.value} key={value}>{name.name}</Option>
                                    })}
                                </Select>
                            </div> <br />
                        </> : ''} */}

            <div className="col-xl-12" style={{ textAlign: "center" }}>
              <h1>
                {props.location.state.data
                  ? props.location.state.data.cardDescription
                  : props.location.state.cardDescription}{" "}
                Products
              </h1>
            </div>
            <div className="col-xl-12" style={{ textAlign: "center" }}>
              <Link to={`/schoolslist/SCHOOL_UNIFORMS`}>
                <Button
                  type="primary"
                  style={{ width: "207px" }}
                  icon={
                    <ArrowLeftOutlined
                      style={{
                        fontSize: "27px",
                        color: "red",
                        position: "absolute",
                        top: "2px",
                        left: "7px",
                      }}
                    />
                  }
                  size="large"
                >
                  <b style={{ float: "right", fontSize: "17px" }}>
                    Back to Schools
                  </b>
                </Button>
              </Link>
            </div>
            {/* <div className="col-xl-12" style={{ textAlign: 'center', marginTop: '5px' }}>
                            {isAuthenticated ? '' :
                                <div className="row">
                                    <div className="col-xl-12">
                                        <Button type="primary" size="large">
                                            Cart Count : {cartCount ? cartCount : 0}
                                        </Button>
                                    </div>
                                </div>
                            }
                        </div> */}
          </div>
        </div>
      </header>
      <div className="ex-cards-1 pt-3 pb-3">
        <div className="container">
          <div className="carticon">
            <a href="/cart">
              <Badge
                count={cartQuantity}
                showZero
                size={100}
                style={{ backgroundColor: "#52c41a", color: "white" }}
              >
                <Avatar
                  size={64}
                  icon={<ShoppingCartOutlined style={{ fontSize: "54px" }} />}
                />
              </Badge>
            </a>
          </div>
          <div className="row">
            {selectedProduct.length > 0 ? (
              selectedProduct.map((product, index) => {
                return (
                  product.showProduct === true || product.showProduct === undefined ? 
                  <div
                    className="col-lg-4"
                    style={{ marginBottom: " 20px" }}
                    key={index}
                  >
                    <div className="product-card">
                      <div className="logo-cart">
                        <img src="./images/logo.png" alt="Smart Uniforms" />
                        <i className="bx bx-shopping-bag"></i>
                      </div>
                      <div className="main-images">
                        <Image
                          className="active"
                          src={product.productImage}
                          alt="Smart Uniforms"
                        />
                      </div>
                      <div className="product_details">
                        <span className="product_name">
                          <b>{product.productName}</b>
                        </span>{" "}
                        <br />
                        {product.productSubDesc ? (
                          <span style={{ color: "red" }}>
                            {product.productSubDesc}
                          </span>
                        ) : (
                          ""
                        )}
                        <p>{product.productDescription}</p>
                      </div>
                      {product.productAvilability === true &&
                      product.onlyProduct === true ? (
                        <div style={{ paddingBottom: "30px" }}>
                          <div style={{ float: "left" }}>
                            <span className="fa-stack fa-2x">
                              <a href="tel:+91 95388 31666">
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i
                                  class="fa fa-phone fa-stack-1x"
                                  style={{ color: "white" }}
                                ></i>
                              </a>
                            </span>
                          </div>
                          <div style={{ float: "right" }}>
                            <span className="fa-stack fa-2x">
                              <a href="https://api.whatsapp.com/send?phone=919538831666">
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i
                                  className="fab fa-whatsapp fa-stack-1x"
                                  style={{ color: "white" }}
                                ></i>
                              </a>
                            </span>
                          </div>
                        </div>
                      ) : product.ProductAvilabeQuantity ? (
                        <span style={{ color: "red" }}>
                          <b>
                            Available Quantity :{" "}
                            {product.ProductAvilabeQuantity} Pcs.
                          </b>
                        </span>
                      ) : (
                        ""
                      )}{" "}
                      <br />
                      {product.productAvilability === true &&
                      product.onlyProduct ===
                        true ? null : product.DescAboutProductAvailability ? (
                        <span style={{ color: "blue", fontSize: "12px" }}>
                          {product.DescAboutProductAvailability}.
                        </span>
                      ) : (
                        ""
                      )}
                      {product.productAvilability === true &&
                      product.onlyProduct ===
                        true ? null : product.productArray ? (
                        product.multiple ? (
                          <div>
                            <Select
                              showSearch
                              style={{ width: "48%", textAlign: "justify" }}
                              placeholder="Select a tshirt size"
                              optionFilterProp="children"
                              onChange={onhandlechange}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {product.productArray.map((data, index) => {
                                var display =
                                  data.price === ""
                                    ? ""
                                    : data.price - data.basePrice !== 0
                                    ? `(+ ₹${data.price - data.basePrice}.00)`
                                    : "";
                                return (
                                  <Option
                                    disabled={data.disabled}
                                    style={{ textAlign: "justify" }}
                                    value={`${data.size}@${product.productId}`}
                                    key={index}
                                  >
                                    <Tooltip
                                      placement="top"
                                      title={`${data.size} ${
                                        data.sizeDescription
                                          ? `${data.sizeDescription},`
                                          : ""
                                      }  ${display}`}
                                    >
                                      {`${data.size} ${
                                        data.sizeDescription
                                          ? `${data.sizeDescription},`
                                          : ""
                                      }  ${display}`}
                                    </Tooltip>
                                  </Option>
                                );
                              })}
                            </Select>{" "}
                            <Select
                              showSearch
                              style={{ width: "48%", textAlign: "justify" }}
                              placeholder="Select a track nicker size"
                              optionFilterProp="children"
                              onChange={onhandlechange1}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {product.productArray1.map((data, index) => {
                                var display =
                                  data.price === ""
                                    ? ""
                                    : data.price - data.basePrice !== 0
                                    ? `(+ ₹${data.price - data.basePrice}.00)`
                                    : "";
                                return (
                                  <Option
                                    disabled={data.disabled}
                                    style={{ textAlign: "justify" }}
                                    value={`${data.size}@${product.productId}`}
                                    key={index}
                                  >
                                    <Tooltip
                                      placement="top"
                                      title={`${data.size} ${
                                        data.sizeDescription
                                          ? `${data.sizeDescription},`
                                          : ""
                                      }  ${display}`}
                                    >
                                      {`${data.size} ${
                                        data.sizeDescription
                                          ? `${data.sizeDescription},`
                                          : ""
                                      }  ${display}`}
                                    </Tooltip>
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        ) : (
                          <div>
                            <Select
                              showSearch
                              style={{ width: "100%", textAlign: "justify" }}
                              placeholder={
                                product.productId === "NH-1114-APRN"
                                  ? "Select a size & Color"
                                  : "Select a size"
                              }
                              optionFilterProp="children"
                              // mode={product.multiple ? 'multiple' : ''}
                              onChange={onChange}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {product.productArray.map((data, index) => {
                                // return
                                var display =
                                  data.price === "" ||
                                  data.productId === "NH-1114-APRN"
                                    ? ""
                                    : data.price - data.basePrice !== 0
                                    ? `(+ ₹${data.price - data.basePrice}.00)`
                                    : "";
                                return (
                                  <Option
                                    disabled={data.disabled}
                                    style={{ textAlign: "justify" }}
                                    value={`${data.size}@${product.productId}`}
                                    key={index}
                                  >
                                    <Tooltip
                                      placement="top"
                                      title={`${data.size} ${
                                        data.sizeDescription
                                          ? `${data.sizeDescription}${
                                              display === "" ? "" : ","
                                            }`
                                          : ""
                                      }   ${display}`}
                                    >
                                      {/* {data.size} {data.sizeDescription}, (+ &#x20B9;{data.price - data.basePrice}.00) */}
                                      {`${data.size} ${
                                        data.sizeDescription
                                          ? `${data.sizeDescription}${
                                              display === "" ? "" : ","
                                            }`
                                          : ""
                                      }   ${display}`}
                                    </Tooltip>
                                  </Option>
                                );
                                // </Tooltip>
                              })}
                            </Select>
                          </div>
                        )
                      ) : null}
                      <div style={{ textAlign: "center", margin: "10px auto" }}>
                        {product.productAvilability === true &&
                        product.onlyProduct ===
                          true ? null : product.productAmount ? (
                          <>
                            <strong
                              style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              Quantity :{" "}
                            </strong>
                            <Button
                              type="primary"
                              shape="circle"
                              onClick={(e) => changeQuantity("MINUS", product)}
                            >
                              {" "}
                              <b className="prodQuanMinus">-</b>{" "}
                            </Button>
                            <span style={{ margin: "12px" }}>
                              {product.productQuantity}
                            </span>
                            <Button
                              type="primary"
                              shape="circle"
                              onClick={(e) => changeQuantity("PLUS", product)}
                            >
                              {" "}
                              <b className="prodQuan">+</b>{" "}
                            </Button>{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="color-price">
                        <div className="color-option">
                          {product.productAvilability === true &&
                          product.onlyProduct ===
                            true ? null : product.productColors ? (
                            <>
                              <span className="color">Colour:</span>
                              <div className="circles">
                                <Select
                                  style={{ width: "100%" }}
                                  placeholder="Select a color"
                                  optionFilterProp="children"
                                  onChange={onColorChange}
                                  optionLabelProp="label"
                                >
                                  {product.productColors.map((col, index) => {
                                    return (
                                      <Option
                                        style={{ background: col.color }}
                                        value={`${col.color}@${product.productId}`}
                                        key={index}
                                        label={col.color}
                                      >
                                        {" "}
                                        <Tooltip
                                          placement="top"
                                          title={col.name}
                                        >
                                          <p>{col.name}</p>{" "}
                                        </Tooltip>
                                        {/* <div> <span style={{background:col.color, height:'18px', width:'18px', borderRadius:'50%'}} id={col.color}></span> </div> */}
                                      </Option>
                                    );
                                  })}
                                </Select>
                                {/* {product.productColors.map((col) => {
                                                    return <span className="circle" style={{background:col.color}} id={col.color}></span>})
                                                    } */}
                                {/* <span className="circle pink " id="pink"></span>
                                                    <span className="circle yellow " id="yellow"></span> */}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        {product.productAvilability === true &&
                        product.onlyProduct ===
                          true ? null : product.productAmount ? (
                          <div className="price">
                            <span className="price_num">
                              &#8377; {product.productAmount}.00
                            </span>
                            <span className="price_letter">
                              Inclusive of all Taxes
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {product.productAvilability === true &&
                      product.onlyProduct ===
                        true ? null : product.productAvilability === false ? (
                        <div
                          style={{
                            color: "red",
                            textAlign: "center",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Out of stock
                        </div>
                      ) : product.productAmount ? (
                        <div className="button">
                          <div className="button-layer"></div>
                          <button
                            onClick={(e) => {
                              addingToCart(product);
                            }}
                          >
                            Add To Cart
                          </button>
                        </div>
                      ) : (
                        <div className="button">
                          <div className="button-layer"></div>
                          <button>
                            {" "}
                            <a
                              href="https://api.whatsapp.com/send?phone=+91 9538831666"
                              style={{ color: "white" }}
                            >
                              Proceed With Whatsapp
                            </a>
                          </button>
                        </div>
                      )}
                      {product.productAvilability === true &&
                      product.onlyProduct ===
                        true ? null : product.productAmount ? (
                        product.productAmount <= 100 ? (
                          ""
                        ) : (
                          <p
                            style={{
                              textAlign: "center",
                              color: "green",
                              fontWeight: "bold",
                            }}
                          >
                            Free Delivery
                          </p>
                        )
                      ) : (
                        ""
                      )}
                      <div className="row">
                        {product.productAvilability === true &&
                        product.onlyProduct ===
                          true ? null : product.productMeasurement &&
                          product.washingInstruction ? (
                          <>
                            <div className="col-lg-6">
                              <Link
                                to={{
                                  pathname: `/measureclothes/${product.value}`,
                                  state: { data: props.location.state },
                                }}
                              >
                                For Measurements
                              </Link>
                            </div>
                            <div className="col-lg-6">
                              <Link
                                to={{
                                  pathname: "/washingandcaring",
                                  state: { data: props.location.state },
                                }}
                              >
                                Washing Instructions
                              </Link>
                            </div>{" "}
                          </>
                        ) : product.productMeasurement ? (
                          <div
                            className="col-lg-12"
                            style={{ textAlign: "center" }}
                          >
                            <Link
                              to={{
                                pathname: `/measureclothes/${product.value}`,
                                state: { data: props.location.state },
                              }}
                            >
                              For Measurements
                            </Link>
                          </div>
                        ) : product.washingInstruction ? (
                          <div
                            className="col-lg-12"
                            style={{ textAlign: "center" }}
                          >
                            <Link
                              to={{
                                pathname: "/washingandcaring",
                                state: { data: props.location.state },
                              }}
                            >
                              Washing Instructions
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div> : null
                ); 
              })
            ) : (
              <div style={{ textAlign: "center" }}>
                <h1 style={{ color: "green" }}>{msg}</h1>
              </div>
            )}
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
}

export default Products;
