import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../actions/authActions'
import { schools, studentClassData } from './Constants/School'

const errStyle = {
    color: 'red',
    textAlign: 'center',
    fontSize: '.7rem'
}

const gender = [{name:'', value:''}, { name: 'Boy', value: 'boy'}, {name: 'Girl', value: 'girl'}]

function Register(props) {
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        password2: '',
        school: '',
        phoneNumber: '',
        studentName: '',
        studentClass: '',
        studentBranch: '',
        studentGender: '',
        userRole:'CUSTOMER'
    })
    const errors = useSelector(state => state.errors)
    const dispatch = useDispatch();

    const handleChage = (e) => {
        const { id, value } = e.target;
        setUser(user => ({ ...user, [id]: value }));
    }

    const submitForm = () => {
        dispatch(registerUser(user, props.history))
    }

    return (
        <div>
            <header className="ex-header">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <h1 className="text-center">Sign Up</h1>
                        </div>
                    </div>
                </div>
            </header>
            <div className="ex-form-1 pt-5 pb-5">
        <div className="container">
            <div className="row">
                <div className="col-xl-6 offset-xl-3">
                    <div className="text-box mt-5 mb-5">
                        <p className="mb-4">Fill out the form below to sign up for the service. Already signed up? Then just <a className="blue" href="/login">Log In</a></p>
                            <div className="form-group">
                                <input type="text" className="form-control-input notEmpty" id="name" onChange={(e) => handleChage(e)} required />
                                <label className="label-control" htmlFor="name">Parent Name</label>
                                <p style={errStyle}>{errors.name}</p>
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control-input notEmpty" id="email" onChange={(e) => handleChage(e)} required />
                                <label className="label-control" htmlFor="email">Parent Email</label>
                                <p style={errStyle}>{errors.email}</p>
                            </div>
                            <div className="form-group">
                                <input type="number" className="form-control-input notEmpty" id="phoneNumber" onChange={(e) => handleChage(e)} required />
                                <label className="label-control" htmlFor="phoneNumber">Parent Mobile</label>
                                <p style={errStyle}>{errors.phoneNumber}</p>
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control-input notEmpty" id="password" onChange={(e) => handleChage(e)} required />
                                <label className="label-control" htmlFor="password">Password</label>
                                <p style={errStyle}>{errors.password}</p>
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control-input notEmpty" id="password2" onChange={(e) => handleChage(e)} required />
                                <label className="label-control" htmlFor="password2">Reenter Password</label>
                                <p style={errStyle}>{errors.password2}</p>
                            </div>
                            {/* school name should be in typing state */}
                            <div className="form-group">
                                <input type="text" className="form-control-input notEmpty" id="school" onChange={(e) => handleChage(e)} required />
                                <label className="label-control" htmlFor="school">School Name</label>
                                <p style={errStyle}>{errors.school}</p>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control-input notEmpty" id="studentBranch" onChange={(e) => handleChage(e)} required />
                                <label className="label-control" htmlFor="studentBranch">School Branch</label>
                                <p style={errStyle}>{errors.studentBranch}</p>
                            </div>
                            {/* <div className="form-group">
                                <select className="form-control-input notEmpty" id="school" onChange={(e) => handleChage(e)} required>
                                    {schools.map((school, index) => {
                                        return <option value={school.value} key={index}>{school.cardDescription}</option>
                                    })}                                  
                                </select>
                                <label className="label-control" htmlFor="school">School</label>
                                <p style={errStyle}>{errors.school}</p>
                            </div> */}
                            <div className="form-group">
                                <input type="text" className="form-control-input notEmpty" id="studentName" onChange={(e) => handleChage(e)} required />
                                <label className="label-control" htmlFor="studentName">Student Name</label>
                                <p style={errStyle}>{errors.studentName}</p>
                            </div>
                            <div className="form-group">
                                {/* <input type="text" className="form-control-input notEmpty" id="studentClass" onChange={(e) => handleChage(e)} required /> */}
                                <select className="form-control-input notEmpty" id="studentClass" onChange={(e) => handleChage(e)} required>
                                    {studentClassData.map((school, index) => {
                                        return <option value={school.value} key={index}>{school.name}</option>
                                    })}                                  
                                </select>
                                <label className="label-control" htmlFor="studentClass">Student Class</label>
                                <p style={errStyle}>{errors.studentClass}</p>
                            </div>
                            <div className="form-group">
                                <select className="form-control-input notEmpty" id="studentGender" onChange={(e) => handleChage(e)} required>
                                    {gender.map((g, index) => {
                                        return <option value={g.value} key={index}>{g.name}</option>
                                    })}                                  
                                </select>
                                <label className="label-control" htmlFor="studentGender">Student Gender</label>
                                <p style={errStyle}>{errors.studentGender}</p>
                            </div>
                            <div className="form-group checkbox" style={{ textAlign: 'center' }}>
                                {/* <input type="checkbox" id="terms" value="Agreed-to-Terms" required /> */}
                                I agree with the site's stated <a href="/privacy">Privacy Policy</a> and <a href="/termsandconditions">Terms & Conditions</a>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="form-control-submit-button" onClick={(e) => submitForm(e)}>Sign Up</button>
                            </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
    );
}

export default Register;